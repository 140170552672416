import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Hospital } from 'src/app/Models/hospital.model';
import { PhotographicConsent } from 'src/app/Models/photographicconsent.model';
import { PatientService } from 'src/app/services/patient.service';
import { PhotographicConsentService } from 'src/app/services/photographic-consent.service';

@Component({
  selector: 'app-view-photographic-consent',
  templateUrl: './view-photographic-consent.component.html',
  styleUrls: ['./view-photographic-consent.component.css'],
})
export class ViewPhotographicConsentComponent implements OnInit {
  id = 0;
  PhotographicConsentList: PhotographicConsent[] = [];
  hospital: Hospital;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private photographicConsentService: PhotographicConsentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });

    this.getPhotographicConsent(this.id);
  }

  getPhotographicConsent(id) {
    this.patientService.GetPhotographicConsent(id).subscribe((res) => {
      this.PhotographicConsentList = res.PhotographicConsents;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.photographicConsentService
      .deletePhotographicConsent(id)
      .subscribe((res) => {
        this.getPhotographicConsent(this.id);
      });
  }
}
