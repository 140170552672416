import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { EmailPayload } from 'src/app/Models/email.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientinsuranceService } from 'src/app/services/patientinsurance.service';
import { environment } from 'src/environments/environment';
import { StripeSource } from 'stripe-angular';

@Component({
  selector: 'app-print-invoice-pt-side',
  templateUrl: './print-invoice-pt-side.component.html',
  styleUrls: ['./print-invoice-pt-side.component.css'],
})
export class PrintInvoicePtSideComponent implements OnInit {
  @ViewChild('Invoice') Invoice!: ElementRef;
  helper = new JwtHelperService();
  patientId = 0;
  myToken = window.localStorage.getItem('token');
  id = 0;
  invoice;
  invalidError = {};
  isPaymentSuccess = false;
  partialAmount = 0;
  email = new FormControl('');
  hide = true;
  insurance;
  hospital: Hospital;
  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private http: HttpClient,
    private toastr: ToastrService,
    private patientInsurance: PatientinsuranceService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPtInvoice(this.id);
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.patientId = parseInt(decodedToken.nameid);
  }

  getPatientInfo(id) {
    this.patientService.getPatientById(id).subscribe({
      next: (res) => {
        this.invoice['PatientId'] = res.PatientId;
        this.invoice['PatientAddress'] = res.PatientAddress;
        this.invoice['PatientDOB'] = res.PatientDOB;
        this.invoice['Email'] = res.Email;
      },
    });
  }

  getPtInvoice(id) {
    this.invoiceService.getInvoiceItems(id).subscribe((res: any) => {
      this.getPatientInfo(res.PatientId);
      this.getPatientHospital(res.PatientId);
      this.getPatientInsurance(res.PatientId);
      this.invoice = res;
    });
  }

  getPatientHospital(id) {
    this.patientService.getpatienHospitalId(id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
  }
  getPatientInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.insurance = res;
    });
  }
  printPage() {
    window.print();
  }
  printPageArea(areaID) {
    var printContent = document.getElementById(areaID).innerHTML;
    var originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  }

  sendtoInsurance() {
    let body = {
      Email: this.email.value,
      Subject: 'Invoice' + this.invoice.InvoiceId,
      Template: `${this.Invoice.nativeElement.innerHTML}`,
    };
    this.patientService.sendInvoiceToInsurance(body).subscribe((res) => {
      alert('Invoice sent');
    });
  }

  sendInvoice(): void {
    let body: EmailPayload = {
      Email: this.invoice['PatientEmail'],
      Subject: 'Invoice' + this.invoice.InvoiceId,
      Template: `${this.Invoice.nativeElement.innerHTML}`,
    };

    this.patientService.sendInvoice(body).subscribe({
      next: (res) => alert('Invoice sent'),
    });
  }

  cardReady = false;
  extraData = {
    name: null,
    address_city: null,
    address_line1: null,
    address_line2: null,
    address_state: null,
    address_zip: null,
    price: 30,
  };

  onStripeInvalid(error: Error) {
    // console.log('Validation Error', error)
  }

  setStripeToken(token) {
    // console.log('Stripe token', token)
    let body = {
      TransactionId: token.id,
      InvoiceId: this.invoice.InvoiceId,
      Amount: this.invoice.TotalAmount,
      PaymentType: 'Card Payment',
      PatientId: this.invoice['PatientId'],
    };

    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res) => {
        if (res['status'] == 'succeeded') {
          this.isPaymentSuccess = true;
        }
      },
      (err) => console.log(err)
    );
  }

  parialPayement(token, amount: number) {
    if (amount < 0) {
      this.toastr.error('You need to enter an amount greater then 0');
      return;
    }

    let body = {};
    if (amount < this.invoice.TotalAmount) {
      body = {
        TransactionId: token.id,
        InvoiceId: this.invoice.InvoiceId,
        Amount: amount,
        PaymentType: 'Card Payment',
        PatientId: this.invoice['PatientId'],
      };
    } else {
      body = {
        TransactionId: token.id,
        InvoiceId: this.invoice.InvoiceId,
        Amount: this.invoice.TotalAmount,
        PaymentType: 'Card Payment',
        PatientId: this.invoice['PatientId'],
      };
    }

    this.http.post(environment.Url + 'Payments', body).subscribe(
      (res) => {
        if (res['status'] == 'succeeded') {
          this.isPaymentSuccess = true;
        }
      },
      (err) => console.log(err)
    );
  }

  setStripeSource(source: StripeSource) {
    // console.log('Stripe source', source)
  }

  onStripeError(error: Error) {
    // console.error('Stripe error')
  }
}
