import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PatientIdproofService } from '../../services/patient-idproof.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-add-patient-idproof',
  templateUrl: './add-patient-idproof.component.html',
  styleUrls: ['./add-patient-idproof.component.css'],
})
export class AddPatientIdproofComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  id = 0;
  imageUrl: string;
  image2Url: string;
  isSuccess = false;
  constructor(
    private route: ActivatedRoute,
    private patientIdProofService: PatientIdproofService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  ptInsuranceFrm = this.fb.group({
    IssuingAuthority: ['', Validators.required],
    DocumentNo: ['', Validators.required],
    IdExpiryDate: ['', Validators.required],
    IdImageFront: [''],
    IdImageBack: [''],
  });

  get IssuingAuthority() {
    return this.ptInsuranceFrm.get('IssuingAuthority');
  }
  get DocumentNo() {
    return this.ptInsuranceFrm.get('DocumentNo');
  }
  get IdExpiryDate() {
    return this.ptInsuranceFrm.get('IdExpiryDate');
  }
  get IdImageFront() {
    return this.ptInsuranceFrm.get('IdImageFront');
  }
  get IdImageBack() {
    return this.ptInsuranceFrm.get('IdImageBack');
  }

  private triggerFront: Subject<any> = new Subject();
  public webcamImageFront!: WebcamImage;
  private nextWebcamFront: Subject<any> = new Subject();
  sysImagefront = '';

  public getSnapshotFront(): void {
    this.triggerFront.next(void 0);
  }
  public captureImgFront(webcamImage: WebcamImage): void {
    this.webcamImageFront = webcamImage;
    this.sysImagefront = webcamImage!.imageAsDataUrl;
  }

  public get invokeObservableFront(): Observable<any> {
    return this.triggerFront.asObservable();
  }
  public get nextWebcamObservableFront(): Observable<any> {
    return this.nextWebcamFront.asObservable();
  }

  private triggerBack: Subject<any> = new Subject();
  public webcamImageBack!: WebcamImage;
  private nextWebcamBack: Subject<any> = new Subject();
  sysImageback = '';

  public getSnapshotBack(): void {
    this.triggerBack.next(void 0);
  }
  public captureImgBack(webcamImage: WebcamImage): void {
    this.webcamImageBack = webcamImage;
    this.sysImageback = webcamImage!.imageAsDataUrl;
  }

  InsuranceCardTabChange(event) {
    this.imageUrl = '';
    this.image2Url = '';

    this.sysImageback = '';
    this.sysImagefront = '';

    this.webcamImageFront = null;
    this.webcamImageBack = null;

    this.frontImageInput!.value = '';
    this.backImageInput!.value = '';
  }

  public get invokeObservableBack(): Observable<any> {
    return this.triggerBack.asObservable();
  }
  public get nextWebcamObservableBack(): Observable<any> {
    return this.nextWebcamBack.asObservable();
  }

  frontImageInput: any;
  backImageInput: any;
  image(e, input) {
    let formData = new FormData();
    let files = e.target.files;
    this.frontImageInput = input;
    formData.append('file', files[0], files[0].name);

    this.patientIdProofService.imageUpload(formData).subscribe(
      (res) => {
        let insImg = environment.imgurl + res['path'];
        this.imageUrl = `<p><img src="${insImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }
  image2(e, input) {
    let formData = new FormData();
    let files = e.target.files;
    this.backImageInput = input;

    formData.append('file', files[0], files[0].name);

    this.patientIdProofService.imageUpload(formData).subscribe(
      (res) => {
        let ins2Img = environment.imgurl + res['path'];
        this.image2Url = `<p><img src="${ins2Img}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  // image(e){
  //   let formData = new FormData();
  //   let files= e.target.files
  //   formData.append('file',files[0],files[0].name)

  // this.patientIdProofService.imageUpload(formData).subscribe(res=>{
  //   let idImg = environment.imgurl + res['path'];
  //   this.imageUrl = `<p><img src="${idImg}"/></p>`
  //    },err=>console.log(err))

  // }
  // image2(e){
  //   let formData = new FormData();
  //   let files= e.target.files
  //   formData.append('file',files[0],files[0].name)

  // this.patientIdProofService.imageUpload(formData).subscribe(res=>{
  //   let id2img = environment.imgurl + res['path'];
  //   this.image2Url = `<p><img src="${id2img}"/></p>`
  //    },err=>console.log(err))

  // }

  formSubmit() {
    let isueAuthority = this.ptInsuranceFrm.value['IssuingAuthority'];
    let documentNo = this.ptInsuranceFrm.value['DocumentNo'];
    let idExpiryDate = this.ptInsuranceFrm.value['IdExpiryDate'];
    idExpiryDate = moment(idExpiryDate).format('MM/DD/YYYY');

    let idImageFront = this.ptInsuranceFrm.value['IdImageFront'];
    let idImageBack = this.ptInsuranceFrm.value['InsImageBack'];

    let body = {
      PatientId: this.id,
      IssuingAuthority: isueAuthority,
      DocumentNo: documentNo,
      IdExpiryDate: idExpiryDate,
      IdImageFront: this.sysImagefront ? this.sysImagefront : this.imageUrl,
      InsImageBack: this.sysImageback ? this.sysImageback : this.image2Url,
    };

    this.patientIdProofService.registerPatientIdproof(body).subscribe((res) => {
      this.isSuccess = true;
      this.ptInsuranceFrm.reset();
      this.submitAction.emit('');
    });
  }
}
