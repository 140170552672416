<div class="container printable" id="printable">
    <app-view-hospital></app-view-hospital>






    <div class="row">
        <div class="col-lg-6 "><span class="float-right"></span></div>
        <div class="col-lg-6 " *ngIf="isHide">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Search by Date</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

            </mat-form-field>
        </div>
    </div>

    <h1 class="text-center" style="font-weight:bold ;">Patent Medical Records</h1>


    <button class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
        value="print"><mat-icon>print</mat-icon></button>
    <div>
        <div class="">
            <h2 class="">Patient Information</h2>

            <div class="row">
                <div class="col-lg-2"><span class="bld">Patient No:</span>
                    {{patient?.PatientId}}</div>
                <div class="col-lg-3">
                    <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}} {{patient?.PatientLastName}}
                </div>
                <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}</div>
                <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}</div>
            </div>
        </div>
        <hr>



        <div class=" mt-5" *ngFor="let item of filteredList ">

            <div class="row">
                <div class="col-lg-6">
                    <h2 class="">Patient Visits Date: {{item?.VistitDate }}</h2>
                    <!-- | date: 'dd/MM/yyyy' -->

                </div>

            </div>
            <div class="row">
                <div class="col-lg-2"> <span class="bld">Doctor:</span> {{item?.DoctorName}}</div>
                <div class="col-lg-3"> <span class="bld">Doctor Speciality:</span> {{item?.DoctorSpecialization}} </div>
                <div class="col-lg-2"><span class="bld">Visit Type:</span> {{item?.VisitType}}</div>
                <div class="col-lg-5"><span class="bld">Visit Date & time:</span> {{item?.createdDate | date :'short'}}
                </div>
                <!-- <div class="col-lg-3"><span class="bld">Visit Time:</span> {{item?.VistTime}}</div> -->
            </div>

            <hr>
            <div class="row">

                <div class="col-lg-2"><span class="bld">BloodPressure:</span> {{item?.BloodPressure}}</div>
                <div class="col-lg-2"><span class="bld">Pulse:</span> {{item?.Pulse}}</div>
                <div class="col-lg-2"><span class="bld">NurseName:</span> {{item?.NurseName}}</div>

            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Diagnosis :</span> {{item?.CptCode}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Subjective :</span> {{item?.Subjective}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Objective :</span> {{item?.Objective}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Assessment :</span> {{item?.Assessment}}</div>
            </div>


            <!-- <div class="row">
        <div class="col-lg-6">
    <h3 class="bld">Patient Complaints</h3>
  
            {{item.PatientComplaints}}
        </div>
        <div class="col-lg-6">
    <h3 class="bld"> Issues Started From</h3>
  
            {{item.IssueStartDate}}
        </div>
       
    </div>
  
  <hr> -->

            <!-- <div class="row">
        <div class="col-lg-6">
            <h3 class="bld">Dignosis</h3>
        
                    {{item.Dignosis}}
                </div>
        <div class="col-lg-6">
    <h3 class="bld">Findings</h3>
  
            {{item.Findings}}
        </div>
        
    </div> -->


            <hr>


            <div class="row">
                <div class="col-lg-12">
                    <!-- <h3 class="bld">Treatments</h3>
        
                    {{item.Treatments}}
                </div> -->


                    <h3 class="bld"> Plan (Patient Note)</h3>

                    <div [innerHTML]="item?.PatientNote"></div>
                </div>
                <!-- <div class="col-lg-2"><h3 class="bld">ConsultationFee:</h3> {{item.ConsultationFee}}</div> -->
            </div>




            <hr>

        </div>
    </div>





</div>