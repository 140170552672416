import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { InvoiceService } from '../../services/invoice.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { CptCode } from 'src/app/Models/cptcode.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { Observable, observable } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css'],
})
export class AddInvoiceComponent implements OnInit {
  patientId = 0;
  id = 0;
  helper = new JwtHelperService();
  flteredList;
  myToken = window.localStorage.getItem('token');
  StatusList: string[] = ['Paid', 'UnPaid', 'Pending', 'Balance'];
  invForm: UntypedFormGroup;
  isSuccess = false;
  cptCodeList: CptCode[];
  hospital: Hospital;
  hospitalId: number;
  UnitCharge: any;
  filteredOptions: Observable<CptCode[]>;

  filteredCountries: Record<string, string>[] = [];

  randomizeOrder() {
    this.filteredCountries.forEach((_, i) => {
      const rand = Math.round(Math.random() * this.filteredCountries.length);
      moveItemInArray(this.filteredCountries, i, rand);
    });
  }
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private invoiceService: InvoiceService,
    private cptCodeService: CptcodeService,
    private clipBoard: Clipboard
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.patientId = res['id'];
    });
    this.getPatient(this.patientId);

    this.patientService.getpatienHospitalId(this.patientId).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    // let decodedToken = this.helper.decodeToken(this.myToken);
    // this.id = parseInt(decodedToken.nameid);

    this.getProcedureCode();

    this.invForm = this.fb.group({
      PatientName: ['', Validators.required],
      ProcedureCodeLookUp: [''],
      DoctorName: [''],
      NpiNumber: [''],
      GroupNo: [''],
      InsuaranceNo: [''],
      InsuaranceName: [''],
      Title: ['', Validators.required],
      InvoiceItems: this.fb.array([]),
      Status: ['', Validators.required],
      Date: ['', Validators.required],
      CoPay: ['', Validators.required],
      SubTotal: [''],
      Total: [''],
      BalanceAmount: [''],
    });
    this.addItem();
    this.getPatientInsurance(this.patientId);
    this.getPatientDoctor(this.patientId);
  }

  public addItem(): void {
    this.UnitCharge = 0;
    const item = this.fb.group({
      Amount: [''],
      Description: [''],
      ProcedureCode: [''],
      Duration: [''],
      Units: [''],
    });
    this.InvoiceItems.push(item);
  }

  public removeItem(i: number): void {
    this.InvoiceItems.removeAt(i);
    this.getGrandTotal();
  }
  get BalanceAmount() {
    return this.invForm.get('BalanceAmount');
  }
  get GroupNo() {
    return this.invForm.get('GroupNo');
  }
  get InsuaranceName() {
    return this.invForm.get('InsuaranceName');
  }
  get InsuaranceNo() {
    return this.invForm.get('InsuaranceNo');
  }
  get ProcedureCodeLookUp() {
    return this.invForm.get('ProcedureCodeLookUp');
  }
  get InvoiceItems(): UntypedFormArray {
    return this.invForm.get('InvoiceItems') as UntypedFormArray;
  }
  get NpiNumber() {
    return this.invForm.get('NpiNumber');
  }
  get DoctorName() {
    return this.invForm.get('DoctorName');
  }
  get CoPay() {
    return this.invForm.get('CoPay');
  }
  get SubTotal() {
    return this.invForm.get('SubTotal');
  }
  get Total() {
    return this.invForm.get('Total');
  }
  get Date() {
    return this.invForm.get('Date');
  }
  get Title() {
    return this.invForm.get('Title');
  }
  get Amount() {
    return this.invForm.get('Amount');
  }
  get Description() {
    return this.invForm.get('Description');
  }
  get ProcedureCode() {
    return this.invForm.get('ProcedureCode');
  }
  get Duration() {
    return this.invForm.get('Duration');
  }
  get Units() {
    return this.invForm.get('Units');
  }
  get Status() {
    return this.invForm.get('Status');
  }
  get PatientName() {
    return this.invForm.get('PatientName');
  }
  getProcedureCode() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  getPatient(id: number) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.PatientName.setValue(
        `${res.PatientFirstName} ${res.PatientLastName}`
      );
      // this.PatientName.setValue(res.PatientLastName);
    });
  }
  getPatientInsurance(id: number) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.InsuaranceName.setValue(res.PatientInsurances[0].InsuaranceName);
      this.InsuaranceNo.setValue(res.PatientInsurances[0].InsuaranceNo);
      this.GroupNo.setValue(res.PatientInsurances[0].GroupNo);
    });
  }

  getPatientDoctor(id: number) {
    this.patientService.getDoctorId(id).subscribe((res) => {
      this.DoctorName.setValue(res.Doctor.DoctorName);
      this.NpiNumber.setValue(res.Doctor.NpiNumber);
    });
  }
  copy(e) {
    this.clipBoard.copy(e);
  }
  formSubmit() {
    let body = {
      ...this.invForm.value,
      PatientId: this.patientId,
      HospitalId: this.hospital.HospitalId,
      SoftwareFee: 1.5,
    };
    this.invoiceService.registerInvoice(body).subscribe((_) => {
      this.isSuccess = true;
      this.invForm.reset();
    });
  }

  public getGrandTotal(): void {
    let total = 0;
    const invoiceItems = this.InvoiceItems.value;
    let x = this.invForm.value['CoPay'];

    invoiceItems.forEach((invoiceItem: { Amount: number }) => {
      if (invoiceItem.Amount) {
        total = total + invoiceItem.Amount;
      }
    });

    total = total - x;
    this.invForm.get('Total').setValue(total);
    this.SubTotal.setValue(total);
    this.BalanceAmount.setValue(total);
  }
  onSelectChange(e) {
    this.UnitCharge = e.UnitCharge;
  }

  amountCalculation() {
    let ad = 0;
    let a = this.InvoiceItems.value;
    a.forEach((element) => {
      let d = element.Units;
      let b = this.UnitCharge;
      ad = d * b;
      this.InvoiceItems.get('Amount').setValue(ad);
    });

    // this.InvoiceItems.get('Amount').setValue(ab);
  }
}
