import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-writing-pad',
  templateUrl: './writing-pad.component.html',
  styleUrls: ['./writing-pad.component.css'],
})
export class WritingPadComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
