<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Patient ID proof</h1>
      <form [formGroup]="ptInsuranceFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Name of Issuing Authority</mat-label>
              <input type="text" formControlName="IssuingAuthority" matInput>
              <mat-error>Please enter Name of Issuing Authority</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>ID No</mat-label>
              <input type="text" formControlName="DocumentNo" matInput>
              <mat-error>Please enter ID No</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label> Expiry Date</mat-label>
              <input type="date" formControlName="IdExpiryDate" matInput>
              <mat-error>Please enter Expiry Date</mat-error>
            </mat-form-field>
          </div>
          <!-- <div>
                    <mat-label> ID Card Front Image </mat-label>
                    <input type="file" (change)="image($event)">
                 </div>
                 <br>
                 <div>
                    <mat-label> ID Card Back Image </mat-label>
                    <input type="file" (change)="image2($event)">
                 </div> -->

          <mat-tab-group class="p-2 mt-2 w-100" (selectedTabChange)="InsuranceCardTabChange($event)">
            <mat-tab label="Web Cam Option" class="w-100" style="height: 300px;">
              <div class="row d-flex justify-centent-center w-100">
                <div class="row mt-5 col ">
                  <h2 class="text-center"> &nbsp;&nbsp;&nbsp;&nbsp;ID Card Front Image</h2>
                  <div class="col-md-12">
                    <webcam [trigger]="invokeObservableFront" (imageCapture)="captureImgFront($event)" [height]="180"
                      [width]="180"></webcam>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-danger" (click)="getSnapshotFront()" type="button">
                      Capture Image
                    </button>
                  </div>
                  <div class="col-12">
                    <div id="results">Your taken image manifests here...</div>
                    <img [src]="webcamImageFront?.imageAsDataUrl" height="180px" />
                  </div>
                </div>

                <div class="row mt-5 col">
                  <h2 class="text-center">ID Card Back Image</h2>
                  <div class="col-md-12">
                    <webcam [trigger]="invokeObservableBack" (imageCapture)="captureImgBack($event)" [height]="180"
                      [width]="180"></webcam>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-danger" (click)="getSnapshotBack()" type="button">
                      Capture Image
                    </button>
                  </div>
                  <div class="col-12">
                    <div id="results">Your taken image manifests here...</div>
                    <img [src]="webcamImageBack?.imageAsDataUrl" height="180px" />
                  </div>
                </div>

              </div>

            </mat-tab>
            <mat-tab label="Upload File Option" style="height: 300px;">
              <div class="row  mt-5 mb-5">
                <div class="col-md-6">
                  <mat-label> Insurance Card Front Image </mat-label>
                  <input type="file" (change)="image($event,frontInputTag)" #frontInputTag>

                </div>

                <div class="col-md-6">

                  <mat-label> Insurance Card Back Image </mat-label>
                  <input type="file" (change)="image2($event,backInputTag)" #backInputTag>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>


        </div>
        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Added Patient ID
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>
</div>