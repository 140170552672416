import { Component } from '@angular/core';

@Component({
  selector: 'app-update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.css']
})
export class UpdatePaymentMethodComponent {

}
