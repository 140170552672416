<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Department</h2>
      <form [formGroup]="dptFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <!-- <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <mat-label>Hospital Name</mat-label>
         

          <mat-select formControlName="HospitalId">

            <mat-option *ngFor="let ho of hospitalList" [value]="ho.HospitalId">{{ho.HospitalName}}</mat-option>

          </mat-select>
          <mat-error >Please select Hospital</mat-error>
        </mat-form-field>
      </div> -->
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Department Name</mat-label>
              <input type="text" formControlName="DepartmentName" matInput>
              <mat-error>Please enter Department Name</mat-error>
            </mat-form-field>
          </div>



          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <input type="text" formControlName="Description" matInput>
              <mat-error>Please enter Description</mat-error>
            </mat-form-field>

          </div>
        </div>





        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Department
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- </div> -->
      </form>

    </div>