<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">radiology</mat-icon>
            Radiology Member
        </ng-template>

        <div class="container">

            <table class="table table">
                <thead>
                    <th>FullName</th>
                    <th>D.O.B</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>NationalId</th>
                    <th>Address</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ph of radiologyMemberList">
                        <td>{{ph.FullName}}</td>
                        <td>{{ph.Dob}}</td>
                        <td>{{ph.Email}}</td>
                        <td>{{ph.PhoneNumber}}</td>
                        <td>{{ph.NationalId}}</td>
                        <td>{{ph.Address}}</td>
                        <td>{{ph.Status}}</td>
                        <td>
                            <button (click)="delete(ph.RadiologyMemberIid)" mat-mini-fab>delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Radiology Member
        </ng-template>
        <app-add-radiology-member></app-add-radiology-member>
    </mat-tab>


</mat-tab-group>