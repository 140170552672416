<div class="container ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Register Radiology Order Subject</h1>
            <form [formGroup]="subFrm" (ngSubmit)="formSubmit()" class="example-form">


                <mat-form-field class="example-full-width">
                    <mat-label>Subject</mat-label>
                    <input type="text" formControlName="Subject" matInput>
                    <mat-error>Please enter Subject</mat-error>

                </mat-form-field>



                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>