<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.CreatedDate | date:'short'}} </td>
        </ng-container>
        <ng-container matColumnDef="DoctorName">
            <th mat-header-cell *matHeaderCellDef> Doctor Name </th>
            <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
        </ng-container>
        <ng-container matColumnDef="IsSendToPharmacy">
            <th mat-header-cell *matHeaderCellDef> Is Send To Pharmacy </th>
            <td mat-cell *matCellDef="let row"> {{row.IsSendToPharmacy?'Sent':'Not Send'}} </td>
        </ng-container>
        <ng-container matColumnDef="InstructionToPharmacy">
            <th mat-header-cell *matHeaderCellDef> Instruction To Pharmacy </th>
            <td mat-cell *matCellDef="let row"> {{row.InstructionToPharmacy}} </td>
        </ng-container>


        <!-- <ng-container matColumnDef="View">
        <th mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let element">
          <button type="button" [routerLink]="['/printlabreport/',element.LabReportId]" mat-mini-fab color="primary">
            <mat-icon matPrefix>report</mat-icon>
          </button>
        </td>
      </ng-container> -->

        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View & Print </th>
            <td mat-cell *matCellDef="let row">
                <button type="button" color="primary" (click)="viewReport(row)" mat-mini-fab data-toggle="modal"
                    data-target=".bd-example-modal-lg">
                    <mat-icon>report</mat-icon>
                </button>

                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">SCript </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body " id="printable" #patientPrescriptionList>
                                <app-view-hospital></app-view-hospital>

                                <div class="areaStyle mb-1">
                                    <P>Patient ID: {{patient?.PatientId}} </P>

                                    <P>Patient Name: {{patient?.PatientFirstName}} &nbsp;
                                        {{patient?.PatientLastName}} </P>
                                    <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                                    <P>Patient Address: {{patient?.PatientAddress}} </P>
                                    <hr>
                                </div>
                                <P>Date: {{row.CreatedDate | date:'short'}}</P>
                                <P>DoctorName: {{row.DoctorName}}</P>
                                <P>Instruction To Pharmacy: {{row.InstructionToPharmacy}}</P>

                                <div>
                                    <table class="table table-striped">
                                        <thead>

                                            <th>Drug Name</th>
                                            <th>Quantity</th>
                                            <th>RefillDuration</th>
                                            <th>StartDate</th>
                                            <th>EndDate</th>
                                            <th>Status</th>
                                            <th>QTime</th>
                                            <th>Instruction</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let script of row.PrescriptionDrugs">
                                                <td>{{script.DrugName}}</td>
                                                <td>{{script.Quantity}}</td>
                                                <td>{{script.RefillDuration}}</td>
                                                <td>{{script.StartDate}}</td>
                                                <td>{{script.EndDate}}</td>
                                                <td>{{script.Status?'Current':'Stopped'}}</td>
                                                <td>{{script.QTime}}</td>
                                                <td>{{script.Instruction}}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <ul class="list-unstyled">

                                    <li>Pharmacy Name : <span>{{pharmacy[0].PharmacyName}}</span>
                                    <li>Pharmacy Address : <span>{{pharmacy[0].PharmacyAddress}}</span>
                                    <li>Pharmacy Phone : <span>{{pharmacy[0].PhoneNumber}}</span>


                                </ul>
                            </div>
                            <hr>
                            <div class="areaStyle">

                                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5"
                                    role="alert">
                                    Successfully send Prescription
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="modal-footer d-flex flex-sm-row flex-column justify-content-between align-items-center">

                                <div class="send-email-prescription d-flex align-items-baseline">
                                    <mat-form-field class="w-100 mr-3" appearance="outline">
                                        <input matInput type="text" [formControl]="email" placeholder="Enter email">
                                    </mat-form-field>
                                    <button mat-raised-button color="primary" class="mb-1"
                                        (click)="sendPresciption()">Send</button>
                                </div>

                                <div>
                                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
                                    <button type="button" class="btn btn-secondary mx-3"
                                        data-dismiss="modal">Close</button>
                                </div>
                                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                            </div>
                        </div>
                    </div>
                </div>

            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>