import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { PaymentmethodService } from 'src/app/services/paymentmethod.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.css'],
})
export class AddPaymentMethodComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  id = 0;
  isSuccess = false;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private paymentMethodService: PaymentmethodService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  phFrm = this.fb.group({
    // DepartmentId:[''],
    ProviderName: ['', Validators.required],
    PublicKey: ['', Validators.required],
    SecurityKey: ['', Validators.required],
    Status: [''],
  });

  get ProviderName() {
    return this.phFrm.get('ProviderName');
  }

  get PublicKey() {
    return this.phFrm.get('PublicKey');
  }

  get SecurityKey() {
    return this.phFrm.get('SecurityKey');
  }

  get Status() {
    return this.phFrm.get('Status');
  }

  formSubmit() {
    let ProviderName = this.phFrm.value['ProviderName'];

    let PublicKey = this.phFrm.value['PublicKey'];
    let SecurityKey = this.phFrm.value['SecurityKey'];
    let status = this.phFrm.value['Status'];

    let body = {
      HospitalId: this.id,
      ProviderName: ProviderName,
      PublicKey: PublicKey,
      SecurityKey: SecurityKey,
      Status: status,
    };

    this.paymentMethodService.registerPaymentMethod(body).subscribe((res) => {
      this.isSuccess = true;
      this.phFrm.reset();
      this.submitAction.emit('');
    });
  }
}
