import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-twofa-passwordreset',
  templateUrl: './twofa-passwordreset.component.html',
  styleUrls: ['./twofa-passwordreset.component.css'],
})
export class TwofaPasswordresetComponent implements OnInit {
  otp: any;
  inputDigit: string = 'Verify Code';
  btnStaus: string = 'btn-light';
  decodedToken: any;
  UserName: any;
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  public configurations = {
    length: 5,
  };

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      console.log(res);
      this.UserName = res['unique_name'];
      this.decodedToken = res;
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  onOtpChange(event: any) {
    this.otp = event;
    if (this.otp.length < this.configurations.length) {
      this.inputDigit =
        this.configurations.length - this.otp.length + ' digits left';
    }
    if (this.otp.length === this.configurations.length) {
      this.inputDigit = 'Go';
      this.btnStaus = 'btn-primary';
    }
  }

  veryFy(e) {
    let body = {
      TwoFactorCode: this.otp.toString(),
      UserName: this.UserName,
    };
    console.log(body);
    this.userService.veriFy(body).subscribe((res) => {
      if (res === true) {
        this.router.navigate([
          'setpassword',
          this.UserName,
          this.otp.toString(),
        ]);
      } else {
        alert('not user found');
      }
    });
  }
}
