<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <!-- *ngIf = "!currentPatient.IsInsuranceCompleted" -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      View Lab Report
    </ng-template>
    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportType">
          <th mat-header-cell *matHeaderCellDef> ReportType </th>
          <td mat-cell *matCellDef="let row"> {{row.ReportType}} </td>
        </ng-container>

        <ng-container matColumnDef="Description">
          <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="OrderedBy">
          <th mat-header-cell *matHeaderCellDef> Ordered By </th>
          <td mat-cell *matCellDef="let row"> {{row.OrderedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="LabName">
          <th mat-header-cell *matHeaderCellDef> Lab Name </th>
          <td mat-cell *matCellDef="let row"> {{row.LabName}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportedBy">
          <th mat-header-cell *matHeaderCellDef> Reported By </th>
          <td mat-cell *matCellDef="let row"> {{row.ReportedBy}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="View">
        <th mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let element">
          <button type="button" [routerLink]="['/printlabreport/',element.LabReportId]" mat-mini-fab color="primary">
            <mat-icon matPrefix>report</mat-icon>
          </button>
        </td>
      </ng-container> -->

        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef> View Report </th>
          <td mat-cell *matCellDef="let row">
            <button type="button" color="primary" (click)="viewLabReport(row)" mat-mini-fab data-toggle="modal"
              data-target=".bd-example-modal-lg">
              <mat-icon>report</mat-icon>
            </button>

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Report </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body " id="printable" #labReports>
                    <app-view-hospital></app-view-hospital>

                    <div class="areaStyle mb-1">
                      <P>Patient ID: {{patient?.PatientId}} </P>

                      <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}}
                      </P>
                      <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                      <P>Patient Address: {{patient?.PatientAddress}} </P>
                      <hr>
                    </div>
                    <P>Date: {{Date | date:'short'}}</P>
                    <P>Subject: {{ReportType}}</P>
                    <P>OrderedBy: {{OrderedBy}}</P>
                    <P>Description: {{Description}}</P>
                    <P>Reported By: {{ReportedBy}}</P>
                    <P>Test Name: {{TestName}}</P>
                  </div>
                  <hr>
                  <div>
                    <table class="table table-striped">
                      <thead>

                        <th>Test Name</th>
                        <th>Test Result</th>
                        <th>Referance Range</th>
                        <th>Units</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rw of labTest">
                          <td>{{rw.TestName}}</td>
                          <td>{{rw.TestResultValue}}</td>
                          <td>{{rw.ReferanceRange}}</td>
                          <td>{{rw.Units}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully send
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-footer">
                    <div class="send-email-prescription d-flex align-items-baseline">
                      <mat-form-field class="w-100 mr-3" appearance="outline">
                        <input matInput type="text" [formControl]="email" placeholder="Enter email">
                      </mat-form-field>
                      <button mat-raised-button color="primary" class="mb-1" (click)="send()">Send</button>
                    </div>
                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                    <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="View">
        <th mat-header-cell *matHeaderCellDef> View Report </th>
        <td mat-cell *matCellDef="let row">
     
          <button type="button" (click)="viewLabReport(row)" class="btn btn-primary" data-toggle="modal"
            data-target="#lab_report_modal">
            View Report
          </button>
  
          <div class="modal fade bd-example-modal-lg" id="lab_report_modal" tabindex="-1" role="dialog"
            aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Lab Report
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body lab-document" id="printable">
  
                  <div [innerHTML]="labDocument"></div>
  
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                  </button>
                  <button type="button" (click)="print()" class="btn btn-secondary mx-3" data-dismiss="modal">
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div> -->

        <!-- modal  -->
        <!-- </td>
      </ng-container> -->
        <!-- <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element">
                <button (click)="delete(element.LabReportId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">upload</mat-icon>
      View Uploaded Lab Report
    </ng-template>
    <!-- (submitAction)="handleSubmitAction()" -->
    <app-view-patient-side-uploaded-labreport></app-view-patient-side-uploaded-labreport>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      Upload New Lab Report
    </ng-template>
    <app-add-upload-lab-repor></app-add-upload-lab-repor>
  </mat-tab>
</mat-tab-group>