import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { PatientService } from 'src/app/services/patient.service';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-patient-pharmacy',
  templateUrl: './view-patient-pharmacy.component.html',
  styleUrls: ['./view-patient-pharmacy.component.css'],
})
export class ViewPatientPharmacyComponent implements OnInit {
  phList: PatientsPharmacy[];
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  constructor(
    private pharmacyService: PharmacyService,
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    // let decodedToken = this.helper.decodeToken(this.myToken);
    // this.id = parseInt(decodedToken.nameid);
    this.getPharmacys(this.id);
  }

  getPharmacys(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.phList = res.PatientsPharmacys;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPharmacys(this.id);
    }
  }

  delete(id) {
    this.pharmacyService.deletePatientsPharmacy(id).subscribe((res) => {
      this.getPharmacys(this.id);
    });
  }
}
