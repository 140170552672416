import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientvisitService } from 'src/app/services/patientvisit.service';
import * as iink from 'iink-js';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { IcdCodeService } from 'src/app/services/icd-code.service';

@Component({
  selector: 'app-update-doctor-patient-visit',
  templateUrl: './update-doctor-patient-visit.component.html',
  styleUrls: ['./update-doctor-patient-visit.component.css'],
})
export class UpdateDoctorPatientVisitComponent implements OnInit {
  id = 0;
  icdCodeList: IcdCode[];
  @ViewChild('undo', { static: true })
  public undoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('redo', { static: true })
  public redoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('pen', { static: true })
  public penBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('eraser', { static: true })
  public erasorBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('editor', { static: true })
  public editor!: ElementRef<HTMLDivElement>;
  selectedIcdCodes: string[] = [];
  addVisitFrm: FormGroup;
  DoctorId: number;
  CurrentDoctorId: number;
  isEraserActive = false;
  isPenActive = true;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  isSuccess = false;
  constructor(
    private route: ActivatedRoute,
    private patientVisitService: PatientvisitService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private plt: Platform,
    private icdCodeService: IcdCodeService,
    private cd: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.addVisitFrm = this.fb.group({
      DoctorName: [''],
      NurseName: [''],
      DoctorSpecialization: [''],
      RoomNo: [''],
      AdmissionDate: [''],
      DischargeDate: [''],
      VisitType: [''],
      VistitDate: [''],
      VistTime: [''],

      PatientNote: [''],
      BloodPressure: [''],
      Pulse: [''],
      IcdCode: [''],
      Objective: [''],
      Subjective: [''],
      Assessment: [''],
      NextFollowUp: [''],
      Signature: [''],
    });

    this.patientVisitService.getPatientVisitById(this.id).subscribe((res) => {
      // console.log('res: ', res);
      this.DoctorId = res.DoctorId;
      let decodedToken = this.helper.decodeToken(this.myToken);
      this.CurrentDoctorId = parseInt(decodedToken.nameid);
      this.DoctorName.setValue(res.DoctorName);
      this.NurseName.setValue(res.NurseName);
      this.DoctorSpecialization.setValue(res.DoctorSpecialization);
      this.RoomNo.setValue(res.RoomNo);
      this.AdmissionDate.setValue(res.AdmissionDate);
      this.DischargeDate.setValue(res.DischargeDate);
      this.VisitType.setValue(res.VisitType);
      this.VistitDate.setValue(res.VistitDate);
      this.VistTime.setValue(res.VistTime);

      this.PatientNote.setValue(res.PatientNote);

      // console.log(res.PatientNote);
      this.BloodPressure.setValue(res.BloodPressure);
      this.Pulse.setValue(res.Pulse);
      this.IcdCode.setValue(res.IcdCode);
      this.Objective.setValue(res.Objective);
      this.Subjective.setValue(res.Subjective);
      this.Assessment.setValue(res.Assessment);
      this.NextFollowUp.setValue(res.NextFollowUp);
      this.Signature.setValue(res.Signature);
      if (res.IcdCode) {
        this.IcdCode.setValue(res.IcdCode);
        if (res.IcdCode.includes(',')) {
          const arr = res.IcdCode.split(',');
          this.selectedIcdCodes = [...arr];
        } else {
          this.selectedIcdCodes = [res.IcdCode];
        }
      }
    });

    this.getsIcdCode();
  }

  get Assessment() {
    return this.addVisitFrm.get('Assessment');
  }
  get Objective() {
    return this.addVisitFrm.get('Objective');
  }
  get Subjective() {
    return this.addVisitFrm.get('Subjective');
  }
  getIcdCode() {
    return this.addVisitFrm.get('IcdCode');
  }

  get DoctorName() {
    return this.addVisitFrm.get('DoctorName');
  }
  get NurseName() {
    return this.addVisitFrm.get('NurseName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get RoomNo() {
    return this.addVisitFrm.get('RoomNo');
  }
  get AdmissionDate() {
    return this.addVisitFrm.get('AdmissionDate');
  }
  get DischargeDate() {
    return this.addVisitFrm.get('DischargeDate');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get VistitDate() {
    return this.addVisitFrm.get('VistitDate');
  }
  get VistTime() {
    return this.addVisitFrm.get('VistTime');
  }

  get PatientNote() {
    return this.addVisitFrm.get('PatientNote');
  }

  get BloodPressure() {
    return this.addVisitFrm.get('BloodPressure');
  }
  get Pulse() {
    return this.addVisitFrm.get('Pulse');
  }
  get NextFollowUp() {
    return this.addVisitFrm.get('NextFollowUp');
  }

  get Signature() {
    return this.addVisitFrm.get('Signature');
  }
  get IcdCode() {
    return this.addVisitFrm.get('IcdCode');
  }
  removeOption(option) {
    const index = this.selectedIcdCodes.indexOf(option);

    if (index >= 0) {
      this.selectedIcdCodes.splice(index, 1);
    }
  }
  getsIcdCode() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.icdCodeList = res;
    });

    // this.http.get('/assets/json/ICD_Code.json').subscribe({
    //   next: (res: any) => {
    //     this.IcdCodeList = res.IcdCodes;
    //   },
    // });
  }

  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let nurseName = this.addVisitFrm.value['NurseName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];
    let roomNo = this.addVisitFrm.value['RoomNo'];
    let admissionDate = this.addVisitFrm.value['AdmissionDate'];
    let dischargeDate = this.addVisitFrm.value['DischargeDate'];
    let visitType = this.addVisitFrm.value['VisitType'];
    let vistitDate = this.addVisitFrm.value['VistitDate'];
    let vistTime = this.addVisitFrm.value['VistTime'];

    let patientNote = this.addVisitFrm.value['PatientNote'];
    let bloodPressure = this.addVisitFrm.value['BloodPressure'];
    let pulse = this.addVisitFrm.value['Pulse'];
    let icdCode = this.addVisitFrm.value['IcdCode'];
    let objective = this.addVisitFrm.value['Objective'];
    let subjective = this.addVisitFrm.value['Subjective'];
    let assessment = this.addVisitFrm.value['Assessment'];
    let nextFollowUp = this.addVisitFrm.value['NextFollowUp'];
    let signature = this.addVisitFrm.value['Signature'];

    let body = {
      DoctorName: doctorName,
      NurseName: nurseName,
      DoctorSpecialization: doctorSpecialization,
      VisitType: visitType,
      VistitDate: vistitDate,
      VistTime: vistTime,
      PatientNote: patientNote,
      BloodPressure: bloodPressure,
      Pulse: pulse,
      IcdCode: icdCode,
      Subjective: subjective,
      Objective: objective,
      Assessment: assessment,
      NextFollowUp: nextFollowUp,
      Signature: signature,
    };

    this.patientVisitService
      .updatePatientVisit(this.id, body)
      .subscribe((res) => {
        this.router.navigate(['/doctorDash/']);
      });
  }

  addOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    // console.log('value: ', value);

    if (!this.selectedIcdCodes.includes(value)) {
      this.selectedIcdCodes.push(value);
      this.IcdCode.setValue(this.selectedIcdCodes.join(','));
    }
  }
}
