import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { CptCode } from 'src/app/Models/cptcode.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { NeurologyVisitService } from 'src/app/services/neurology-visit.service';

@Component({
  selector: 'app-add-neurology-visit',
  templateUrl: './add-neurology-visit.component.html',
  styleUrls: ['./add-neurology-visit.component.css'],
})
export class AddNeurologyVisitComponent implements OnInit {
  isSuccess = false;
  selectedCPTCodes: string[] = [];
  cptCodeList: CptCode[] = [];
  filteredValues: CptCode[];
  id = 0;
  constructor(
    private fb: FormBuilder,
    private cptCodeService: CptcodeService,
    private route: ActivatedRoute,
    private neurologyVisitService: NeurologyVisitService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getCptCodes();

    this.CptCode.valueChanges.subscribe((newValue) => {
      this.filteredValues = this.filterValues(newValue);
    });
  }
  filterValues(search: string): CptCode[] {
    const filterValue = search.toLowerCase();
    return this.cptCodeList.filter(function (opt) {
      if (opt.Code.includes(filterValue)) {
        return opt;
      }
    });
  }

  Frm = this.fb.group({
    PatientId: this.id,
    ChiefComplaint: ['', Validators.required],
    PresentIllness: ['', Validators.required],
    Medical: ['', Validators.required],
    Surgical: ['', Validators.required],
    Allergies: ['', Validators.required],
    Medications: ['', Validators.required],
    SocialHistory: ['', Validators.required],
    FamilyHistory: ['', Validators.required],
    ReviewOfSymptoms: ['', Validators.required],
    VitalSigns: ['', Validators.required],
    HENT: ['', Validators.required],
    Neck: ['', Validators.required],
    Chest: ['', Validators.required],
    Heart: ['', Validators.required],
    Abdomen: ['', Validators.required],
    Extermities: ['', Validators.required],
    Neurological: ['', Validators.required],
    Radiology: ['', Validators.required],
    Impression: ['', Validators.required],
    Recomendation: ['', Validators.required],
    DoctorName: ['', Validators.required],
    Signature: ['', Validators.required],
    Date: ['', Validators.required],
    CptCode: [''],
  });

  get Date() {
    return this.Frm.get('Date');
  }
  get Signature() {
    return this.Frm.get('Signature');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }
  get Recomendation() {
    return this.Frm.get('Recomendation');
  }
  get Impression() {
    return this.Frm.get('Impression');
  }
  get Radiology() {
    return this.Frm.get('Radiology');
  }
  get Neurological() {
    return this.Frm.get('Neurological');
  }
  get Extermities() {
    return this.Frm.get('Extermities');
  }
  get Abdomen() {
    return this.Frm.get('Abdomen');
  }
  get Heart() {
    return this.Frm.get('Heart');
  }
  get Chest() {
    return this.Frm.get('Chest');
  }

  get Neck() {
    return this.Frm.get('Neck');
  }
  get HENT() {
    return this.Frm.get('HENT');
  }
  get VitalSigns() {
    return this.Frm.get('VitalSigns');
  }
  get ReviewOfSymptoms() {
    return this.Frm.get('ReviewOfSymptoms');
  }
  get FamilyHistory() {
    return this.Frm.get('FamilyHistory');
  }
  get SocialHistory() {
    return this.Frm.get('SocialHistory');
  }
  get Medications() {
    return this.Frm.get('Medications');
  }
  get Allergies() {
    return this.Frm.get('Allergies');
  }
  get Surgical() {
    return this.Frm.get('Surgical');
  }
  get Medical() {
    return this.Frm.get('Medical');
  }

  get ChiefComplaint() {
    return this.Frm.get('ChiefComplaint');
  }
  get PresentIllness() {
    return this.Frm.get('PresentIllness');
  }
  get CptCode() {
    return this.Frm.get('CptCode');
  }

  addOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    // console.log('value: ', value);

    if (!this.selectedCPTCodes.includes(value)) {
      this.selectedCPTCodes.push(value);
      this.CptCode.setValue(this.selectedCPTCodes.join(','));
    }
  }
  removeOption(option) {
    const index = this.selectedCPTCodes.indexOf(option);

    if (index >= 0) {
      this.selectedCPTCodes.splice(index, 1);
    }
  }

  getCptCodes() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  formSubmit() {
    this.neurologyVisitService
      .registerNeurologyVisit(this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
