import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PaymentMethod } from 'src/app/Models/paymentmethod.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentmethodService } from 'src/app/services/paymentmethod.service';

@Component({
  selector: 'app-view-payment-method',
  templateUrl: './view-payment-method.component.html',
  styleUrls: ['./view-payment-method.component.css'],
})
export class ViewPaymentMethodComponent implements OnInit {
  patmentMethodList: PaymentMethod[];
  hospitalId = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  constructor(
    private paymentMethodService: PaymentmethodService,
    private hospitalService: HospitalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.hospitalId = parseInt(decodedToken.nameid);
    this.getPaymentMethod(this.hospitalId);
  }

  getPaymentMethod(id) {
    this.hospitalService.getPaymentMethod(id).subscribe((res) => {
      this.patmentMethodList = res.PaymentMethods;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPaymentMethod(this.hospitalId);
    }
  }

  delete(id) {
    this.paymentMethodService.deletePaymentMethod(id).subscribe((res) => {
      this.getPaymentMethod(this.hospitalId);
    });
  }
}
