<!-- <ion-header [style.visibility]="content_visibility">
  <ion-toolbar color="primary">
    <ion-title>
      QR Code
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [style.visibility]="content_visibility"> -->



<div class="lgForm mt-5">
  <button mat-raised-button color="primary" expand="block" class="ion-margin" (click)="startScan()">
    Scan QR Code
  </button>
</div>
<!--   
    <ion-item *ngIf="scannedResult">
      <ion-label class="ion-text-wrap">Scanned Result: {{scannedResult}}</ion-label>
    </ion-item> -->