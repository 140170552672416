import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommunicationAssessment } from 'src/app/Models/communicationassessment.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { CommunicationAssessmentService } from 'src/app/services/communication-assessment.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-communication-assessment',
  templateUrl: './view-communication-assessment.component.html',
  styleUrls: ['./view-communication-assessment.component.css'],
})
export class ViewCommunicationAssessmentComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  nurseId = 0;
  CurrentDoctorId: number;

  CommunicationList: CommunicationAssessment[] = [];
  nurse: Nurse;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private nurseService: NurseService,
    private communicationAssessmentService: CommunicationAssessmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    // let decodedToken = this.helper.decodeToken(this.myToken);
    // this.nurseId = parseInt(decodedToken.nameid);
    // this.getNurse(this.nurseId);
    this.getIntakeAssessment(this.id);
  }

  getIntakeAssessment(id) {
    this.patientService.getCommunicationAssessment(id).subscribe((res) => {
      let decodedToken = this.helper.decodeToken(this.myToken);
      this.nurseId = parseInt(decodedToken.nameid);
      this.CommunicationList = res.CommunicationAssessments;
    });
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getIntakeAssessment(this.id);
    }
  }
  delete(id) {
    this.communicationAssessmentService
      .deleteCommunicationAssessment(id)
      .subscribe((res) => {
        this.getIntakeAssessment(this.id);
      });
  }
}
