import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { IntakeAssessment } from 'src/app/Models/intakeassessment.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-intake-assessment',
  templateUrl: './view-intake-assessment.component.html',
  styleUrls: ['./view-intake-assessment.component.css'],
})
export class ViewIntakeAssessmentComponent implements OnInit {
  id = 0;
  intakeList: IntakeAssessment[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getIntakeAssessment(this.id);
  }

  getIntakeAssessment(id) {
    this.patientService.getIntakeAssessment(id).subscribe((res) => {
      this.intakeList = res.IntakeAssessments.sort(function (x, y) {
        return y.IntakeAssessmentId - x.IntakeAssessmentId;
      });
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getIntakeAssessment(this.id);
    }
  }
}
