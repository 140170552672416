import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BloodSugar } from 'src/app/Models/bloodsugar.model';
import { CptCode } from 'src/app/Models/cptcode.model';
import { BloodSugarService } from 'src/app/services/blood-sugar.service';

@Component({
  selector: 'app-view-blood-sugar',
  templateUrl: './view-blood-sugar.component.html',
  styleUrls: ['./view-blood-sugar.component.css'],
})
export class ViewBloodSugarComponent implements OnInit {
  displayedColumns: string[] = ['TestDate', 'SugarLevel', 'Delete'];
  sugarList: BloodSugar[] = [];
  dataSource: MatTableDataSource<BloodSugar>;
  selectedFile: File;

  constructor(private sugarService: BloodSugarService) {}

  ngOnInit(): void {
    this.getBloodSugar();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getBloodSugar() {
    this.sugarService.getBloodSugars().subscribe((res) => {
      // if (res.filter((x) => x.SugarLevel > 125)) {
      //   alert('Sugar Level is too high urgently vist a physician ');
      // } else if (res.filter((x) => x.SugarLevel > 109)) {
      //   alert('Sugar Level warning please take precaution');
      // }
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    });
  }

  delete(id) {
    this.sugarService.deleteBloodSugar(id).subscribe((res) => {
      this.getBloodSugar();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getBloodSugar();
    }
  }
}
