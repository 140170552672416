<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <form [formGroup]="mriFrm" (ngSubmit)="formSubmit()" class="example-form">
                <!-- <div class="row">
            <div class="col-md-6 col-12">
              <mat-form-field class="w-100">
                <mat-label>Title</mat-label>
                <input type="text" matInput ngModel name="Title">
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
              <mat-form-field class="w-100">
                <mat-label>Title</mat-label>
                <input type="text" matInput ngModel name="Title">
              </mat-form-field>
            </div>
          </div> -->
                <div class="row">
                    <div class="col-lg-6">
                        <h2>Upload Lab Reports</h2>
                        <!-- accept=".DCM, .dcm" -->
                        <input type="file" (change)="image($event)">
                    </div>
                    <button class="float-right lgBtCl" mat-button>
                        <mat-icon>save</mat-icon>
                    </button>
                    <div class="row">
                    </div>
                    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                        You have Successfully submitted
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </form>
            <div class="my-3">
                <!-- <mat-progress-bar mode="determinate"></mat-progress-bar> -->
                <mat-progress-spinner class="example-margin" [value]="uploadProgress">
                </mat-progress-spinner>
                <p>Once the upload progress is complete, press the save button.</p>
            </div>
        </div>

        <!-- 
        <mat-tab-group class="p-2 mt-2 w-100" (selectedTabChange)="InsuranceCardTabChange($event)">
            <mat-tab label="Web Cam Option" class="w-100" style="height: 300px;">
                <div class="row d-flex justify-centent-center w-100">

                    <div class="row mt-5">
                        <div class="col-md-12">
                            <h4 class="ml-3">Capture Image from Camera</h4>


                            <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)"
                                [height]="480" [width]="480" [videoOptions]="videoOptions"
                                [allowCameraSwitch]="allowCameraSwitch">
                            </webcam>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-danger ml-3" (click)="getSnapshot($event)" type="button">
                                Capture Image
                            </button>
                        </div>
                        <div class="col-12">
                            <div id="results">Your taken image manifests here...</div>
                            <img [src]="webcamImage?.imageAsDataUrl" height="480px" />
                        </div>
                    </div>



                </div>

            </mat-tab>
            <mat-tab label="Upload File Option" style="height: 300px;">
                <div class="row  mt-5 mb-5">
                    <div class="col-lg-6">
                        <h2>Upload Lab Reports</h2>
                        <input type="file" (change)="image($event)">
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group> -->