import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { WardService } from '../../services/ward.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-add-ward',
  templateUrl: './add-ward.component.html',
  styleUrls: ['./add-ward.component.css'],
})
export class AddWardComponent implements OnInit {
  isSuccess = false;
  helper = new JwtHelperService();

  id = 0;
  myToken = window.localStorage.getItem('token');
  constructor(
    private fb: UntypedFormBuilder,
    private wardService: WardService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  wardFrm = this.fb.group({
    WardName: ['', Validators.required],
    WardDescription: ['', Validators.required],
  });

  get WardName() {
    return this.wardFrm.get('WardName');
  }
  get WardDescription() {
    return this.wardFrm.get('WardDescription');
  }

  formSubmit() {
    let wardName = this.wardFrm.value['WardName'];
    let wardDescription = this.wardFrm.value['WardDescription'];

    let body = {
      HospitalId: this.id,
      WardName: wardName,
      WardDescription: wardDescription,
    };

    this.wardService.registerWard(body).subscribe((res) => {
      this.isSuccess = true;
      this.wardFrm.reset();
    });
  }
}
