<div class=" mt-5 mb-5">
  <h2 class="text-center">Medication Given Time</h2>

  <table class="w-100 table ">
    <thead>
      <th>Morning </th>
      <th>Nurse Name</th>
      <th>Afternoon </th>
      <th>Nurse Name</th>
      <th>Evening </th>
      <th>Nurse Name</th>
      <th>Night </th>
      <th>Nurse Name</th>
    </thead>
    <tbody>
      <tr *ngFor="let item of medicationGiven">
        <td>{{item?.MorningGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
        <td>{{item?.MorningNurseName}}</td>
        <td>{{item?.AfternoonGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
        <td>{{item?.AfternoonNurseName}}</td>
        <td>{{item?.EveningGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
        <td>{{item?.EveningNurseName}}</td>
        <td>{{item?.NightGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
        <td>{{item?.NightNurseName}}</td>
      </tr>
    </tbody>
  </table>
</div>