<div class="d-flex flex-column p-4" id="printable">
    <h3 class="text-center">Nursing Assessment</h3>
    <mat-tab-group color="primary">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">personal_injury</mat-icon>
                Patient Details
            </ng-template>
            <div class="row mx-0">
                <div class="col-md-6">
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Patient Info</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Death on Arrival</td>
                                        <td>{{nursingReport?.DOA ? 'Yes' : 'No'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Patient Id Band No.</td>
                                        <td>{{nursingReport?.IdBand}}</td>
                                    </tr>
                                    <tr>
                                        <td>Bed No.</td>
                                        <td>{{nursingReport?.BedNo}}</td>
                                    </tr>
                                    <tr>
                                        <td>Arriving Date</td>
                                        <td>{{nursingReport?.Date | date: 'MM-dd-yyyy'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Arriving Time</td>
                                        <td>{{nursingReport?.Time}}</td>
                                    </tr>
                                    <tr>
                                        <td>Treating Doctor</td>
                                        <td>{{nursingReport?.TreatingDoctor}}</td>
                                    </tr>
                                    <tr>
                                        <td>Department</td>
                                        <td>{{nursingReport?.Department}}</td>
                                    </tr>
                                    <tr>
                                        <td>Reason of Admission</td>
                                        <td>{{nursingReport?.ReasonForAdmission}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-content>
                                <h1 class="section__title">Dependencies</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Hearing Aids</td>
                                        <td>{{nursingReport?.HearingAid}}</td>
                                    </tr>
                                    <tr>
                                        <td>Dentures</td>
                                        <td>{{nursingReport?.Dentures}}</td>
                                    </tr>
                                    <tr>
                                        <td>Contact Lens</td>
                                        <td>{{nursingReport?.ContactLens}}</td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{nursingReport?.OtherDependentTech}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-content>
                                <h1 class="section__title">Orientation</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Rooms</td>
                                        <td>{{nursingReport?.Rooms}}</td>
                                    </tr>
                                    <tr>
                                        <td>Belongings</td>
                                        <td>{{nursingReport?.Belongings}}</td>
                                    </tr>
                                    <tr>
                                        <td>Visiting Hours</td>
                                        <td>{{nursingReport?.VisitingTime}}</td>
                                    </tr>
                                    <tr>
                                        <td>Rights and Responsibilities</td>
                                        <td>{{nursingReport?.RightAndResponsibilities}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Diet
                                    &
                                    Nutrition</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Veg</td>
                                        <td>{{nursingReport?.Veg}}</td>
                                    </tr>
                                    <tr>
                                        <td>Non Veg</td>
                                        <td>{{nursingReport?.NonVeg}}</td>
                                    </tr>
                                    <tr>
                                        <td>Egg</td>
                                        <td>{{nursingReport?.Egg}}</td>
                                    </tr>
                                    <tr>
                                        <td>Garlic Free</td>
                                        <td>{{nursingReport?.GarlicFree}}</td>
                                    </tr>
                                    <tr>
                                        <td>Onion Free</td>
                                        <td>{{nursingReport?.OnionFree}}</td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{nursingReport?.OthersDietAndNutrition}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-content>
                                <h1 class="section__title">
                                    Allergies
                                </h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Drug</td>
                                        <td>{{nursingReport?.Drug}}</td>
                                    </tr>
                                    <tr>
                                        <td>Food</td>
                                        <td>{{nursingReport?.Food}}</td>
                                    </tr>
                                    <tr>
                                        <td>Blood Transfusion</td>
                                        <td>{{nursingReport?.BloodTranfusion}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-content>
                                <h1 class="section__title">Habits (since)</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Smoking</td>
                                        <td>{{nursingReport?.Smoking}}</td>
                                    </tr>
                                    <tr>
                                        <td>Alcohol</td>
                                        <td>{{nursingReport?.Alcohol}}</td>
                                    </tr>
                                    <tr>
                                        <td>Tobbaco</td>
                                        <td>{{nursingReport?.Tobbaco}}</td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{nursingReport?.OthersHabits}}</td>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-content>
                                <h1 class="section__title">Others</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Description</td>
                                        <td>{{nursingReport?.Others}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">assignment_ind</mat-icon>
                Assessments
            </ng-template>
            <div class="row mx-0">
                <div class="col-md-6">
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">
                                    Previous History
                                </h1>
                                <table class="section__table">
                                    <tr>
                                        <td>DM</td>
                                        <td>{{nursingReport?.DM}}</td>
                                    </tr>
                                    <tr>
                                        <td>HTN</td>
                                        <td>{{nursingReport?.HTN}}</td>
                                    </tr>
                                    <tr>
                                        <td>BA</td>
                                        <td>{{nursingReport?.BA}}</td>
                                    </tr>
                                    <tr>
                                        <td>TB</td>
                                        <td>{{nursingReport?.TB}}</td>
                                    </tr>
                                    <tr>
                                        <td>Epilepsy</td>
                                        <td>{{nursingReport?.Epilepsy}}</td>
                                    </tr>
                                    <tr>
                                        <td>Cardiac</td>
                                        <td>{{nursingReport?.Cardiac}}</td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{nursingReport?.OthersPreviousMedicalHistory}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Tempratures</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>HR</td>
                                        <td>{{nursingReport?.HR}}</td>
                                    </tr>
                                    <tr>
                                        <td>RR</td>
                                        <td>{{nursingReport?.RR}}</td>
                                    </tr>
                                    <tr>
                                        <td>BP</td>
                                        <td>{{nursingReport?.BP}}</td>
                                    </tr>
                                    <tr>
                                        <td>Ht</td>
                                        <td>{{nursingReport?.Ht}}</td>
                                    </tr>
                                    <tr>
                                        <td>Wt</td>
                                        <td>{{nursingReport?.Wt}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>

                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Cardiovascular Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Rythm</td>
                                        <td>{{nursingReport?.Rythm}}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume</td>
                                        <td>{{nursingReport?.Volume}}</td>
                                    </tr>
                                    <tr>
                                        <td>Chest Pain</td>
                                        <td>{{nursingReport?.ChestPain}}</td>
                                    </tr>
                                    <tr>
                                        <td>Edema</td>
                                        <td>{{nursingReport?.Edema}}</td>
                                    </tr>
                                    <tr>
                                        <td>Cyanosis</td>
                                        <td>{{nursingReport?.Cyanosis}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>

                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Pain Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Location</td>
                                        <td>{{nursingReport?.Location }}</td>
                                    </tr>
                                    <tr>
                                        <td>Severity</td>
                                        <td>{{nursingReport?.Severity }}</td>
                                    </tr>
                                    <tr>
                                        <td>Intervention</td>
                                        <td>{{nursingReport?.Intervention }}</td>
                                    </tr>
                                    <tr>
                                        <td>Assessment Frequency</td>
                                        <td>{{nursingReport?.AssessmentFrequency }}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>

                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Respiratory Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Normal</td>
                                        <td>{{ nursingReport?.Normal ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Dysponea</td>
                                        <td>{{ nursingReport?.Dyspnea ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Wheeze</td>
                                        <td>{{ nursingReport?.Wheeze ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Gasping</td>
                                        <td>{{ nursingReport?.Gasping ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Cough</td>
                                        <td>{{ nursingReport?.Cough ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Oxygen</td>
                                        <td>{{ nursingReport?.Oxygen ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Haemoptysis</td>
                                        <td>{{ nursingReport?.Haemoptysis ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Expectorant</td>
                                        <td>{{ nursingReport?.Expectorant ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{ nursingReport?.OthersRespiratoryAssessment ? 'Yes' : 'No' }} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>

                    </div>

                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Skin Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Normal</td>
                                        <td>{{ nursingReport?.NormalSkinAssessment ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Pale</td>
                                        <td>{{ nursingReport?.Pale ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Cyanotic</td>
                                        <td>{{ nursingReport?.Cyanotic ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Icterus</td>
                                        <td>{{ nursingReport?.Icterus ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Dehydrated</td>
                                        <td>{{ nursingReport?.Dehydrated ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Sweaty</td>
                                        <td>{{ nursingReport?.Sweaty ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Cold</td>
                                        <td>{{ nursingReport?.Cold ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Redness</td>
                                        <td>{{ nursingReport?.Redness }} </td>
                                    </tr>
                                    <tr>
                                        <td>Lession</td>
                                        <td>{{ nursingReport?.Lession }} </td>
                                    </tr>
                                    <tr>
                                        <td>Wound</td>
                                        <td>{{ nursingReport?.Wound }} </td>
                                    </tr>
                                    <tr>
                                        <td>Abrasion</td>
                                        <td>{{ nursingReport?.Abrasion ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{ nursingReport?.OtherSkinAssessment }} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section_-title">Git Asessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Normal</td>
                                        <td>{{ nursingReport?.NormalGitAssessment ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Nausea/Vomiting</td>
                                        <td>{{ nursingReport?.NauseaOrVomiting ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Abdominal Distention</td>
                                        <td>{{ nursingReport?.AbdominalDistension ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Tenderness</td>
                                        <td>{{ nursingReport?.Tenderness ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Diarrhea</td>
                                        <td>{{ nursingReport?.Diarrhea ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Constipation</td>
                                        <td>{{ nursingReport?.Constipation ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Incontinence</td>
                                        <td>{{ nursingReport?.Incontinence ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Last Bowel Movement</td>
                                        <td>{{nursingReport?.LastBowelMovement}} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Urinary Tract Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Normal</td>
                                        <td>{{ nursingReport?.NormalUrinaryAssessment ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Incontinence</td>
                                        <td>{{ nursingReport?.UrinaryAssessmentIncontinence ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Retention</td>
                                        <td>{{ nursingReport?.Retention ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Hematuria</td>
                                        <td>{{ nursingReport?.Hematuria ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Catheter Type</td>
                                        <td>{{ nursingReport?.CatheterType}} </td>
                                    </tr>
                                    <tr>
                                        <td>Inserted </td>
                                        <td>{{ nursingReport?.InsertedOn}} </td>
                                    </tr>
                                    <tr>
                                        <td>UrineColor </td>
                                        <td>{{ nursingReport?.UrineColor}} </td>
                                    </tr>

                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">CNS Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Concious</td>
                                        <td>{{ nursingReport?.Concious ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Dizziness</td>
                                        <td>{{ nursingReport?.Dizziness ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Disoriented</td>
                                        <td>{{ nursingReport?.Disoriented ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Unresponsive</td>
                                        <td>{{ nursingReport?.Unresponsive ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Oriented</td>
                                        <td>{{ nursingReport?.Oriented ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Speech</td>
                                        <td>{{ nursingReport?.Speech ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Swallow</td>
                                        <td>{{ nursingReport?.Swallow ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>PupilAssessment</td>
                                        <td>{{ nursingReport?.PupilAssessment ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Gcs</td>
                                        <td>{{ nursingReport?.Gcs ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>GcsScore</td>
                                        <td>{{ nursingReport?.GcsScore}} </td>
                                    </tr>

                                    <tr>
                                        <td>Tremors</td>
                                        <td>{{ nursingReport?.Tremors ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Paralysis</td>
                                        <td>{{ nursingReport?.Paralysis ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Convulsion</td>
                                        <td>{{ nursingReport?.Convulsions ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td>{{ nursingReport?.OthersCNSAssessment ? 'Yes' : 'No' }} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Gynecology</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>LMP</td>
                                        <td>{{ nursingReport?.GynecologyLmp }} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Fall risk Assessment</h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Vulnerable</td>
                                        <td>{{ nursingReport?.Vulnerable ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Side Rails on</td>
                                        <td>{{ nursingReport?.SideRails ? 'Yes' : 'No' }} </td>
                                    </tr>
                                    <tr>
                                        <td>Attendant Informed</td>
                                        <td>{{ nursingReport?.AttendantInformed ? 'Yes' : 'No' }} </td>
                                    </tr>

                                </table>
                            </mat-card-content>
                        </mat-card>

                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Mediation
                                    Reconcillation
                                </h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Others</td>
                                        <td>{{ nursingReport?.MedicationReconciliation }} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="pb-3">
                        <mat-card class="mat-elevation-z8 mt-3">
                            <mat-card-content>
                                <h1 class="section__title">Nursing
                                    Plan of
                                    Care
                                </h1>
                                <table class="section__table">
                                    <tr>
                                        <td>Others</td>
                                        <td>{{ nursingReport?.NursingPlanOfCare }} </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>

                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>