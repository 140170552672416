<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">radiology</mat-icon>
            Radiology Test Items
        </ng-template>

        <div class="ml-3">
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class=" mat-elevation-z8">

            <div class="d-flex justify-content-end m-2">
                <form (ngSubmit)="uploadFile()">
                    <input type="file" class="formControl" (change)=" onFileSelected($event)" accept="application/json">
                    <button mat-raised-button color="primary" type="submit btn btn-warning">Upload Subjects</button>
                </form>

            </div>

            <table mat-table [dataSource]="dataSource" matSort>


                <ng-container matColumnDef="Subject">
                    <th class="message" mat-header-cell *matHeaderCellDef mat-sort-header>Subject </th>
                    <td class="message" mat-cell *matCellDef="let row"> {{row.Subject}} </td>
                </ng-container>


                <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-mini-fab color="accent" (click)="deleteSubject(row.RadiologyOrderSubjectId)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="Detail">
                <th  mat-header-cell *matHeaderCellDef> Patient Details</th>
                <td mat-cell *matCellDef="let element"> 
                  <button type="button"  [routerLink]="['/doctorPatient/',element.PatientId]" mat-mini-fab color="primary">
                    <mat-icon matPrefix>remove_red_eye</mat-icon>
                  </button>
                </td>
              </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Radiolgy Test Items
        </ng-template>
        <app-add-radiology-order-subject></app-add-radiology-order-subject>
    </mat-tab>


</mat-tab-group>