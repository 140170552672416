import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PatientPrescriptionService } from 'src/app/services/patient-prescription.service';
import { PrescriptionDrugsService } from 'src/app/services/prescription-drugs.service';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-update-patient-prescriptiion',
  templateUrl: './update-patient-prescriptiion.component.html',
  styleUrls: ['./update-patient-prescriptiion.component.css'],
})
export class UpdatePatientPrescriptiionComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  states;
  isSuccess = false;
  doctor: Doctor;
  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];

  instructionList: string[] = [
    'Take 1 tablet in the Morning',
    'Take 1 tablet in the Afternoon',
    'Take 1 tablet in the Evening',
    'Take 1 tablet at Night',
    'Take 1 tablet b.i.d as needed',
    'Take 1 tablet t.i.d as needed',
    'Take 1 tablet every hour',
    'Take 1 tablet every 2 hours',
    'Take 1 tablet every 4 hours',
    'Take 1 tablet every 6 hours',
    'Take 1 tablet every 8 hours',
    'Take 1 tablet every 12 hours',
  ];

  prescriptionFrm: FormGroup;
  medicineList: Medicine[];
  constructor(
    private fb: UntypedFormBuilder,
    private patientPrescriptionService: PatientPrescriptionService,
    private prescriptionDrugService: PrescriptionDrugsService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getMedicine();

    this.patientPrescriptionService
      .getPatientPrescriptionDrugs(this.id)
      .subscribe((res) => {
        this.DoctorName.setValue(res.DoctorName);
        this.InstructionToPharmacy.setValue(res.InstructionToPharmacy);
        this.IsSendToPharmacy.setValue(res.IsSendToPharmacy);
        res.PrescriptionDrugs.forEach((items, index) => {
          this.addItem();
          this.PrescriptionDrugs.controls[index].patchValue(items);
        });
      });
    this.prescriptionFrm = this.fb.group({
      DoctorId: this.doctorId,
      DoctorName: [''],
      DoctorSignature: [''],
      IsSendToPharmacy: [false],
      InstructionToPharmacy: [''],
      PrescriptionDrugs: this.fb.array([]),
    });
  }

  public addItem(): void {
    const item = this.fb.group({
      PrescriptionDrugId: [''],
      DrugName: ['', Validators.required],
      Quantity: ['', Validators.required],
      Instruction: ['', Validators.required],
      MorningTime: [''],
      AfternoonTime: [''],
      EveningTime: [''],
      NightTime: [''],
      QTime: [''],
      Status: [null, Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      RefillDuration: ['', Validators.required],

      // DoctorSignature: [''],
    });
    this.PrescriptionDrugs.push(item);
  }

  public removeItem(i, itemId): void {
    this.prescriptionDrugService.deletePrescriptionDrug(itemId).subscribe();
    this.PrescriptionDrugs.removeAt(i);
  }
  get PrescriptionDrugs(): UntypedFormArray {
    return this.prescriptionFrm.get('PrescriptionDrugs') as UntypedFormArray;
  }

  get InstructionToPharmacy() {
    return this.prescriptionFrm.get('InstructionToPharmacy');
  }
  get IsSendToPharmacy() {
    return this.prescriptionFrm.get('IsSendToPharmacy');
  }

  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Dosage() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
  }
  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }
  public updatePrescriptionsDrugs(pid, i) {
    console.log(pid, i);
    const body = {
      PrescriptionDrugId: this.PrescriptionDrugs.controls[i].value,
      PatientPrescriptionId: this.id,
    };
    // const id = this.PrescriptionDrugs.controls[i].value['PrescriptionDrugId'];
    // delete body['PrescriptionDrugId'];
    // body['PatientPrescriptionId'] = this.id;
    this.prescriptionDrugService.updatePrescriptionDrug(pid, body).subscribe();
  }
  formSubmit() {
    this.patientPrescriptionService
      .updatePatientPrescription(this.id, this.prescriptionFrm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.router.navigate(['/doctorDash']);
      });
  }
}
