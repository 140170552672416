import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientinsuranceService } from 'src/app/services/patientinsurance.service';

@Component({
  selector: 'app-view-patient-insurance',
  templateUrl: './view-patient-insurance.component.html',
  styleUrls: ['./view-patient-insurance.component.css']
})
export class ViewPatientInsuranceComponent implements OnInit {
  Insurances:PatientInsurance[];
id=0
displayStyle = 'none';


  constructor(private patientService:PatientService,private route:ActivatedRoute,private ptInsuaranceService:PatientinsuranceService) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id= res['id']
    })
    this. getPtInsurance(this.id)
  }
  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }

  getPtInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.Insurances = res.PatientInsurances;
    });
  }
  delete(id){
    this.ptInsuaranceService.deletePatientInsurance(id).subscribe(res=>{
      this.getPtInsurance(this.id);
    })
  }
}
