import { DoctorService } from './../../services/doctor.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientvisitService } from '../../services/patientvisit.service';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Doctor } from 'src/app/Models/doctor.model';
import * as moment from 'moment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionAssessmentService } from 'src/app/services/voice-recognition-assessment.service';
import { VoiceRecognitionPlanService } from 'src/app/services/voice-recognition-plan.service';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { IcdCodeService } from 'src/app/services/icd-code.service';

@Component({
  selector: 'app-add-patient-visit',
  templateUrl: './add-patient-visit.component.html',
  styleUrls: ['./add-patient-visit.component.css'],
})
export class AddPatientVisitComponent implements OnInit {
  cities!: IcdCode[];

  selectedCities!: IcdCode[];

  id = 0;
  myControl = new FormControl('');
  selectedIcdCodes: string[] = [];
  options: string[] = ['New Patient', 'Follow Up'];
  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];
  doctorList: Doctor[] = [];
  isSuccess = false;
  DoctorId: Number;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  icdCodeList: IcdCode[] = [];
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  ctx: CanvasRenderingContext2D;
  brush: any;
  strokes: any[] = [];
  tempStrokesArray: any[] = [];
  currentStroke: any = {};
  currentStrokeIndex: number = 0;
  previousStrokeIndex: number = 0;
  previousStrokeLength: number = 0;
  resultsArray: any[] = [];
  hasUndo: boolean = false;
  undoStrokesArray: any[] = [];
  backwardCount: number = 0;
  drawPadTab = false;
  textTab = true;
  ExportedData = '';
  filteredOptions: Observable<IcdCode[]>;
  filteredValues: IcdCode[];
  hospitalId: number;
  message: string;
  listening = false;
  listeningObjective = false;
  listeningAssessment = false;
  listeningPatientNote = false;

  subjectiveText: string = '';
  subjectiveTextArr: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private patientVisitService: PatientvisitService,
    private fb: UntypedFormBuilder,
    private doctorService: DoctorService,
    private cd: ChangeDetectorRef,
    private icdCodeService: IcdCodeService,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    public service: VoiceRecognitionService,
    public serviceObjective: VoiceRecognitionObjectiveService,
    public serviceAssessment: VoiceRecognitionAssessmentService,
    public servicePlan: VoiceRecognitionPlanService
  ) {
    this.service.init();
    this.serviceObjective.init();
    this.serviceAssessment.init();
    this.servicePlan.init();
  }

  ngOnInit(): void {
    this.getIcdCodes();
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });

    this.IcdCode.valueChanges.subscribe((newValue) => {
      this.filteredValues = this.filterValues(newValue);
    });
  }
  filterValues(search: string): IcdCode[] {
    const filterValue = search.toLowerCase();
    return this.icdCodeList.filter(function (opt) {
      if (opt.Code.includes(filterValue)) {
        return opt;
      }
    });
  }

  addVisitFrm = this.fb.group({
    DoctorName: ['', Validators.required],
    NurseName: [''],
    DoctorSpecialization: ['', Validators.required],
    VisitType: ['', Validators.required],
    VistitDate: ['', Validators.required],
    VistTime: ['', Validators.required],
    PatientNote: [''],
    BloodPressure: [''],
    Pulse: [''],
    IcdCode: [''],
    Objective: [''],
    Subjective: [''],
    Assessment: [''],
    NextFollowUp: [''],
    Signature: [''],
  });
  addOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    // console.log('value: ', value);

    if (!this.selectedIcdCodes.includes(value)) {
      this.selectedIcdCodes.push(value);
      this.IcdCode.setValue(this.selectedIcdCodes.join(','));
    }
  }
  removeOption(option) {
    const index = this.selectedIcdCodes.indexOf(option);

    if (index >= 0) {
      this.selectedIcdCodes.splice(index, 1);
    }
  }
  get Assessment() {
    return this.addVisitFrm.get('Assessment');
  }
  get Objective() {
    return this.addVisitFrm.get('Objective');
  }
  get Subjective() {
    return this.addVisitFrm.get('Subjective');
  }
  get IcdCode() {
    return this.addVisitFrm.get('IcdCode');
  }
  get BloodPressure() {
    return this.addVisitFrm.get('BloodPressure');
  }
  get Pulse() {
    return this.addVisitFrm.get('Pulse');
  }
  get Doctor() {
    return this.addVisitFrm.get('DoctorName');
  }
  get NurseName() {
    return this.addVisitFrm.get('NurseName');
  }

  get NextFollowUp() {
    return this.addVisitFrm.get('NextFollowUp');
  }
  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }
  get Signature() {
    return this.addVisitFrm.get('Signature');
  }

  // get RoomNo(){
  //   return this.addVisitFrm.get('RoomNo')
  // }

  // get DischargeDate(){
  // return this.addVisitFrm.get('DischargeDate')
  // }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get VistitDate() {
    return this.addVisitFrm.get('VistitDate');
  }
  get VistTime() {
    return this.addVisitFrm.get('VistTime');
  }
  // get ConsultationFee(){
  // return this.addVisitFrm.get('ConsultationFee')
  // }
  // get PatientComplaints(){
  // return this.addVisitFrm.get('PatientComplaints')
  // }
  // get IssueStartDate(){
  // return this.addVisitFrm.get('IssueStartDate')
  // }
  // get Dignosis(){
  // return this.addVisitFrm.get('Dignosis')
  // }
  // get Findings(){
  // return this.addVisitFrm.get('Findings')
  // }
  // get Treatments(){
  // return this.addVisitFrm.get('Treatments')
  // }
  get PatientNote() {
    return this.addVisitFrm.get('PatientNote');
  }
  // get AdmissionDate(){
  //   return this.addVisitFrm.get('AdmissionDate')
  // }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }

  getIcdCodes() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.icdCodeList = res;
    });
    // this.http.get('https://binsera.com/dload/ICD_Code.json').subscribe(res =>{
    //   console.log("res", res)
    // })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.IcdCodeList.filter = filterValue.trim().toLowerCase();
  }
  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  onObjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }

  onAssessmentBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  onPatientNoteBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }

  startServiceObjective() {
    this.serviceObjective.startObjective();
    this.listeningObjective = true;
  }
  stopServiceObjective() {
    this.serviceObjective.stopObjective();
    this.listeningObjective = false;
  }

  onObjectiveChange(e) {
    this.serviceObjective.Objective = e.target.value;
  }

  startServiceAssessment() {
    this.serviceAssessment.startAssessment();
    this.listeningAssessment = true;
  }
  stopServiceAssessment() {
    this.serviceAssessment.stopAssessment();
    this.listeningAssessment = false;
  }
  onAssessmentChange(e) {
    this.serviceAssessment.Assessment = e.target.value;
  }
  onPatientNoteChange(e) {
    this.servicePlan.PatientNote = e.target.value;
  }
  startServicePatientNote() {
    this.servicePlan.startPatientNote();
    this.listeningPatientNote = true;
  }
  stopServicePatientNote() {
    this.servicePlan.stopPatientNote();
    this.listeningPatientNote = false;
  }
  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let nurseName = this.addVisitFrm.value['NurseName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];

    let visitType = this.addVisitFrm.value['VisitType'];
    let vistitDate = this.addVisitFrm.value['VistitDate'];
    vistitDate = moment(vistitDate).format('MM/DD/YYYY');
    let vistTime = this.addVisitFrm.value['VistTime'];

    let patientNote = this.addVisitFrm.value['PatientNote'];
    let bloodPressure = this.addVisitFrm.value['BloodPressure'];
    let pulse = this.addVisitFrm.value['Pulse'];
    let icdCode = this.addVisitFrm.value['IcdCode'];
    let objective = this.addVisitFrm.value['Objective'];
    let subjective = this.addVisitFrm.value['Subjective'];
    let assessment = this.addVisitFrm.value['Assessment'];
    let nextFollowUp = this.addVisitFrm.value['NextFollowUp'];
    let signature = this.addVisitFrm.value['Signature'];

    let body = {
      HospitalId: this.hospitalId,
      PatientId: this.id,
      DoctorName: doctorName,
      NurseName: nurseName,
      DoctorSpecialization: doctorSpecialization,
      DoctorId: this.DoctorId,
      VisitType: visitType,
      VistitDate: vistitDate,
      VistTime: vistTime,
      PatientNote: patientNote.length ? patientNote : this.ExportedData,
      BloodPressure: bloodPressure,
      Pulse: pulse,
      IcdCode: icdCode,
      Subjective: subjective,
      Objective: objective,
      Assessment: assessment,
      NextFollowUp: nextFollowUp,
      Signature: signature,
      SoftwareFee: 1.5,
      // SoftwareFee: 2,
    };
    // console.log(body);

    this.patientVisitService.registerPatientVisit(body).subscribe((res) => {
      this.isSuccess = true;
      this.addVisitFrm.reset();
      this.router.navigate(['/doctorPatient/', this.id]);
    });
  }
}
// "RoomNo":roomNo,
// "AdmissionDate":admissionDate,
// "DischargeDate":dischargeDate,

// "ConsultationFee":consultationFee,
// "PatientComplaints":patientComplaints,
// "IssueStartDate":issueStartDate,
//  "Dignosis":dignosis,
//  "Findings":findings,
//  "Treatments":treatments,
