import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';
import { PatientPrescription } from 'd:/Centralised_OPD_ProgressiveApp/OPD/src/app/Models/patientprescription.model';
import { PrescriptionDrug } from 'src/app/Models/prescriptiondrug.model';

@Component({
  selector: 'app-view-prescriptions-today',
  templateUrl: './view-prescriptions-today.component.html',
  styleUrls: ['./view-prescriptions-today.component.css'],
})
export class ViewPrescriptionsTodayComponent implements OnInit {
  helper = new JwtHelperService();
  showItem = '';
  hospitalId = 0;
  myToken = window.localStorage.getItem('token');
  currentPrintedPrescriptionDate = '';
  displayedColumns: string[] = [
    'Date',
    'DoctorName',
    'IsSendToPharmacy',
    'InstructionToPharmacy',
    'Note',
    'View',
  ];
  DrugName: string = '';
  Quantity: any = '';
  Instruction: string = '';
  Status: boolean;
  Date: string = '';
  ptDrugs: PrescriptionDrug[];

  id = 0;
  prescriptionList: Prescription[] = [];
  groupedPrescriptions: string[] = [];

  dataSource: MatTableDataSource<any>;
  email = new FormControl('');
  fax = new FormControl('');
  patient: Patient;
  isSuccess: boolean;
  Insurance: PatientInsurance[];
  pharmacy: PatientsPharmacy[];
  Frm: FormGroup;
  doctorList: Doctor[] = [];
  patientPrescriptionList: PatientPrescription[];
  prescriptionId: PatientPrescription[];
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private prescriptionService: PrescriptionService,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private hospitalService: HospitalService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.hospitalId = parseInt(decodedToken.nameid);

    this.getDoctors(this.hospitalId);
    this.Frm = this.fb.group({
      DoctorId: [''],
    });
    this.getPrescription();

    this.getPatient(this.id);
    this.getInsurance(this.id);
    this.getPharmacy(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('prescriptionList') prescription!: ElementRef<any>;

  ngAfterViewInit() {}

  get DoctorId() {
    return this.Frm.get('DoctorId');
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPrescription();
    }
  }
  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getPrescription() {
    this.doctorService
      .getDoctorPrescriptions(this.DoctorId.value)
      .subscribe((res) => {
        this.patientPrescriptionList = res.PatientPrescriptions;
        this.prescriptionId = res.PatientPrescriptions.filter(
          (x) => x.PatientPrescriptionId
        );
        this.dataSource = new MatTableDataSource(
          res.PatientPrescriptions.sort(function (x, y) {
            return y.PatientPrescriptionId - x.PatientPrescriptionId;
          })
        );
        this.dataSource.paginator = this.paginator;
      });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  getInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.Insurance = res.PatientInsurances;
    });
  }

  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
      this.email.setValue(res.PatientsPharmacys[0].Email);
      this.fax.setValue(res.PatientsPharmacys[0].FaxNumber);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  PrintPrescription(date: string) {
    this.currentPrintedPrescriptionDate = date;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      // console.log('pres', this.prescription.nativeElement.innerText);
    }, 0);
  }

  print() {
    window.print();
  }
  public viewReport(report: PatientPrescription): void {
    this.DrugName = report.CreatedDate;
    this.Quantity = report.DoctorName;
    this.Instruction = report.InstructionToPharmacy;
    this.ptDrugs = report.PrescriptionDrugs;

    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
    }, 0);
  }
  sendPresciption() {
    let body = {
      Email: this.email.value,
      Subject:
        this.patient.PatientFirstName +
        ' ' +
        this.patient.PatientLastName +
        "'s" +
        'Prescription',
      Template: `${this.prescription.nativeElement.innerHTML}`,
    };
    this.patientService.sendPrescription(body).subscribe((res) => {
      this.isSuccess = true;
    });
  }
  faxPresciption() {
    let body = {
      FaxNumber: this.fax.value,
      Subject:
        this.patient.PatientFirstName +
        ' ' +
        this.patient.PatientLastName +
        "'s" +
        'Prescription',
      Template: `${this.prescription.nativeElement.innerHTML}`,
    };
    this.patientService.sendPrescriptionFax(body).subscribe((res) => {
      this.isSuccess = true;
    });
  }
  // getPrescriptionOfOneDay(date: string) {
  //   return this.prescriptionList.filter((e) => {
  //     var d = new Date(e.CreatedDate);
  //     d.setHours(0, 0, 0, 0);
  //     return date == d.toDateString();
  //   });
  // }
  getPrescriptionOfOneDay(date: string) {
    let x = this.prescriptionList.filter((e) => {
      var d = new Date(e.CreatedDate);
      d.setHours(0, 0, 0, 0);
      return date == d.toDateString();
    });
    for (let index = 0; index < x.length; index++) {
      const element = x[index];
      if (index > 0) {
        (element.DoctorName = null), (element.NurseName = null);
      }
    }
    return x;
  }
  // delete(id) {
  //   this.prescriptionService.deletePrescription(id).subscribe((res) => {
  //     this.getPrescription();
  //   });
  // }
}
