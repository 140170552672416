import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITool, IViewerProvider } from 'ngx-dicom';
import { IDicomConfig, ToolModeEnum } from 'ngx-dicom';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { MriService } from 'src/app/services/mri.service';
import { PatientService } from 'src/app/services/patient.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-mri',
  templateUrl: './view-mri.component.html',
  styleUrls: ['./view-mri.component.css'],
})
export class ViewMriComponent {
  imageUrl: RadiologyMri[] = [];

  hideDicom: boolean = true;
  config: IDicomConfig = {
    // fileUrl: this.imageUrl,
    fileUrl: '',
    // fileUrl: "http://localhost:4200/assets/0002.DCM",
    tools: [
      {
        name: 'DragProbe',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Eraser',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Magnify',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'StackScrollMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Active,
      },
      {
        name: 'Rotate',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Pan',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'ZoomMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Length',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Angle',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'FreehandRoi',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Wwwc',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
    ],
    classList: 'canvas-container',
  };

  viewerProvider: IViewerProvider | undefined;
  radioDocument: any;

  exportStateToJson(): void {
    this.download(
      JSON.stringify(
        this.viewerProvider?.cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()
      ),
      'toolsState.json',
      'application/json'
    );
  }

  saveAs(): void {
    this.viewerProvider?.cornerstoneTools.SaveAs(
      this.viewerProvider?.element,
      'screenshot.png'
    );
  }

  activateTool(name: string): void {
    const foundTool = this.config.tools?.find((tool) => tool.name === name);
    if (foundTool && this.viewerProvider) {
      this.viewerProvider.cornerstoneTools['setToolActive'](
        name,
        foundTool.options
      );
    }
  }

  isActive(tool: ITool): boolean {
    return this.viewerProvider?.cornerstoneTools.isToolActiveForElement(
      this.viewerProvider?.element,
      tool.name
    );
  }

  private download(content: any, fileName: string, contentType: string) {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private mriService: MriService,
    private patientService: PatientService
  ) {}
  id = 0;
  imagUrl: string;
  isSuccess = false;
  uploadProgress: number = 0;
  ngOnInit() {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatientMri(this.id);
  }

  loadUrl(image: string): void {
    this.config.fileUrl = image;
    this.hideDicom = false;
    setTimeout(() => (this.hideDicom = true));
  }

  getPatientMri(id) {
    this.patientService.getRadiologyMRI(id).subscribe((res) => {
      this.imageUrl = res.RadiologyMris.map((item) => {
        const imgDate = new Date(item.createdDate);
        item.createdDate = `${imgDate.getMonth()}/${imgDate.getDate()}/${imgDate.getFullYear()},${imgDate.getHours()} : ${imgDate.getMinutes()}`;
        return item;
      });
      //  this.config.fileUrl = res.RadiologyMris[1].MriImage;
      //  this.hideDicom = false;
      //  setTimeout(
      //   ()=> this.hideDicom= true
      //  )

      // console.log('Consfig: %o', this.config)
      // console.log('Consfig: %o', this.config)
    });
  }
  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.mriService.imageUpload(formData).subscribe(
      (res: HttpEvent<any>) => {
        if (res.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((100 * res.loaded) / res.total);
        } else if (res.type === HttpEventType.Response) {
          let stImg = environment.imgurl + res.body['path'];
          this.imagUrl = stImg;
          this.uploadProgress = 100;
        }
      },
      ({ error }) => {
        console.log(error);
        // alert(error.message)
      }
    );
  }

  upload(id) {}
  delete(id) {
    this.mriService.deleteRadiologyMri(id).subscribe((res) => {
      this.getPatientMri(this.id);
    });
  }

  formSubmit(eid) {
    console.log(eid);
    let body = {
      StudyReport: this.imagUrl,
    };
    this.mriService.updateRadiologyMri(eid, body).subscribe((res) => {
      this.isSuccess = true;
    });
  }

  public viewRdiologyReport(report: RadiologyMri): void {
    // console.log('report: ', report);
    this.radioDocument = report.StudyReport;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const radiologyImage = document.querySelector(
        '.rad-document img'
      ) as HTMLImageElement;
      if (radiologyImage) {
        radiologyImage.style.width = '100%';
      }
    }, 0);
  }
}
