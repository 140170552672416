import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DutyRosterByShift } from 'src/app/Models/dutyrosterbyshift.model';
import { Patient } from 'src/app/Models/patient.model';
import { Ward } from 'src/app/Models/ward.model';
import { DutyRosterByShiftService } from 'src/app/services/duty-roster-by-shift.service';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-view-duty-roster-by-shift',
  templateUrl: './view-duty-roster-by-shift.component.html',
  styleUrls: ['./view-duty-roster-by-shift.component.css'],
})
export class ViewDutyRosterByShiftComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'ChargeNurse',
    'View',
    'Edit',
    // 'Delete',
  ];
  dataSource: MatTableDataSource<DutyRosterByShift>;

  wardList: Ward[] = [];
  Frm: FormGroup;
  dutyRosterByShift: DutyRosterByShift[] = [];

  constructor(
    private wardService: WardService,
    private dutyRoseterService: DutyRosterByShiftService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getWard();

    this.Frm = this.fb.group({
      WardId: [''],
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get WardId() {
    return this.Frm.get('WardId');
  }

  getWard() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  getDutyRoster() {
    this.wardService
      .getDutyRosterByShift(this.WardId.value)
      .subscribe((res) => {
        // this.dutyRosterByShift = res.DutyRosterByShifts;
        this.dataSource = new MatTableDataSource(res.DutyRosterByShifts);
        this.dataSource.paginator = this.paginator;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
