import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Invoice } from 'src/app/Models/invoice.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientBooking } from '../../Models/patientbooking.model';
import { AppointmentService } from '../../services/appointment.service';
import { BookedslotsService } from '../../services/bookedslots.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-view-appointment',
  templateUrl: './view-appointment.component.html',
  styleUrls: ['./view-appointment.component.css'],
})
export class ViewAppointmentComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'Delete',
  ];
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  id = 0;
  ptAppointments: PatientBooking[] = [];
  dataSource: MatTableDataSource<any>;
  filteredList;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private bookedSlotService: BookedslotsService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getAppt(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt(id) {
    this.patientService.getPatientBooking(id).subscribe((res) => {
      console.log(res);
      // this.filteredList = res.PatientBookings
      this.dataSource = new MatTableDataSource(res.PatientBookings);
      this.dataSource.paginator = this.paginator;
      //  this.ptAppointments= res.PatientBookings
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptAppointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.appointmentService.deletePatientBooking(id).subscribe((res) => {
      this.bookedSlotService.deletePatientBookedSlot(id).subscribe((res) => {
        alert('Cancelled');
      });
      // alert('Cancelled')

      this.getAppt(this.id);
    });
  }
}
