<app-navbar></app-navbar>

<div class="lgForm  ">

    <div class="">
        <h2 class="clr">Forgot Password ?</h2>
        <h4>Enter UserName address associated with your account below</h4>

        <div *ngIf="isUserExists" class="alert alert-danger">{{throwError}}</div>
        <div *ngIf="successMessage!=''" class="alert alert-success">{{successMessage}}</div>
        <form [formGroup]="resetpassForm" (ngSubmit)="formSubmit()">

            <mat-form-field class="example-full-width">
                <mat-label>UserName</mat-label>
                <mat-icon matPrefix>UserName</mat-icon>
                <input type="text" formControlName="UserName" matInput>
                <mat-error *ngIf="UserName.invalid && UserName.touched">Please enter your UserName</mat-error>
            </mat-form-field>
            <button class="btn btn-primary float-right lgBtCl text-white" mat-mini-fab>
                <mat-icon matSuffix>trending_up</mat-icon>

            </button>

        </form>


    </div>

</div>