import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { DoctorService } from '../../services/doctor.service';
import { Doctor } from '../../Models/doctor.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Patient } from 'src/app/Models/patient.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppointmentService } from '../../services/appointment.service';

@Component({
  selector: 'app-view-doctor-appointments',
  templateUrl: './view-doctor-appointments.component.html',
  styleUrls: ['./view-doctor-appointments.component.css'],
})
export class ViewDoctorAppointmentsComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'Detail',
    'Delete',
  ];

  doctor: Doctor;

  id = 0;
  ptAppointments: PatientBooking[] = [];

  filteredList;
  dataSource: MatTableDataSource<any>;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  constructor(
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe(res=>{
    //   this.id= res['id'];
    // })

    this.getAppt(this.id);
    this.getDoctor(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt(id) {
    this.doctorService.getDoctorBooking(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.PatientBookings.sort(function (x, y) {
          return y.PatientBookingId - x.PatientBookingId;
        })
      );
      this.dataSource.paginator = this.paginator;
      this.filteredList = res.PatientBookings;

      this.ptAppointments = res.PatientBookings;
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptAppointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
  delete(id) {
    this.appointmentService.deletePatientBooking(id).subscribe((res) => {
      this.getAppt(this.id);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
