<div class="container w-100 mt-5" id="printable">
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <apx-chart [series]="heartRateChart.series" [chart]="heartRateChart.chart"
                    [xaxis]="heartRateChart.xAxis" [title]="heartRateChart.title"></apx-chart>
            </div>
            <div class="row">
                <apx-chart [series]="respirationChart.series" [chart]="respirationChart.chart"
                    [xaxis]="respirationChart.xAxis" [title]="respirationChart.title"></apx-chart>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row">
                <apx-chart [series]="tempratureChart.series" [chart]="tempratureChart.chart"
                    [xaxis]="tempratureChart.xAxis" [title]="tempratureChart.title"></apx-chart>
            </div>
            <div class="row" *ngIf="bloodPressureChart.xAxis?.categories?.length > 0">
                <apx-chart [series]="bloodPressureChart.series" [chart]="bloodPressureChart.chart"
                    [xaxis]="bloodPressureChart.xAxis" [title]="bloodPressureChart.title"></apx-chart>
            </div>
        </div>
    </div>


</div>