import { Component, OnInit } from '@angular/core';
import { NursingAssessment } from 'src/app/Models/nursingassessment.model';
import { NursingAssessmentService } from '../../services/nursing-assessment.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-nurse-assessment',
  templateUrl: './view-nurse-assessment.component.html',
  styleUrls: ['./view-nurse-assessment.component.css'],
})
export class ViewNurseAssessmentComponent implements OnInit {
  nursingReport: NursingAssessment;
  NursingReportId: number | string;

  constructor(
    private nursingAssessmentService: NursingAssessmentService,
    private route: ActivatedRoute
  ) {
    this.NursingReportId = route.snapshot.params['id'];
    if (this.NursingReportId) {
      this.getNursingPlanById(this.NursingReportId);
    }
  }

  ngOnInit(): void {}

  getNursingPlanById(NursingReportId): void {
    this.nursingAssessmentService
      .getNursingAssessmentById(NursingReportId)
      .subscribe((res) => {
        this.nursingReport = res;
      });
  }
}
