<div>

    <button mat-raised-button color="primary" [routerLink]="['/hospitalDash/']"><mat-icon mat-list-icon>undo</mat-icon>
        Dashboard</button>
</div>

<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
        </ng-container>


        <ng-container matColumnDef="PatientName">
            <th mat-header-cell *matHeaderCellDef> PatientName </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
        </ng-container>
        <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let row"> {{row.Title}} </td>
        </ng-container>
        <ng-container matColumnDef="CoPay">
            <th mat-header-cell *matHeaderCellDef> CoPay </th>
            <td mat-cell *matCellDef="let row"> {{row.CoPay}} </td>
        </ng-container>
        <ng-container matColumnDef="InsurancePay">
            <th mat-header-cell *matHeaderCellDef> InsurancePay </th>
            <td mat-cell *matCellDef="let row"> {{row.InsurancePay}} </td>
        </ng-container>
        <ng-container matColumnDef="AdjustmentAmount">
            <th mat-header-cell *matHeaderCellDef> AdjustmentAmount </th>
            <td mat-cell *matCellDef="let row"> {{row.AdjustmentAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="BalanceAmount">
            <th mat-header-cell *matHeaderCellDef> BalanceAmount </th>
            <td mat-cell *matCellDef="let row"> {{row.BalanceAmount}} </td>
        </ng-container>

        <ng-container matColumnDef="Total">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let row"> {{row.Total}} </td>
        </ng-container>


        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="Print">
            <th mat-header-cell *matHeaderCellDef> Print </th>
            <td mat-cell *matCellDef="let element">
                <button type="button" [routerLink]="['/printInvoicePatient/',element.InvoiceId]" mat-mini-fab
                    color="primary">
                    <mat-icon matPrefix>print</mat-icon>
                </button>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>