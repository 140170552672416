<!-- <div id="dashboard"> -->

<!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
<!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
<div [class]="navigation ? 'hidden' : 'toolbar mat-elevation-z6'">


  <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">

    <div class="ItemS ">
      <mat-icon mat-list-icon>Nurse Dashboard</mat-icon>
      <div class="mx-2" [routerLink]="['/nurseDash/']">dashboard</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <!--  
             <div class="ItemS" (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
               <mat-icon mat-list-icon>add</mat-icon>
               <div class="mx-2"> Nurse Dashboard</div>
               <mat-icon>navigate_next</mat-icon>
             </div> -->
    <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2"> All Patient</div>
      <mat-icon>navigate_next</mat-icon>
    </div>



    <div class="ItemS" (click)="showItem = '3'" [ngClass]="{ active: showItem == '3' }">
      <mat-icon mat-list-icon>science</mat-icon>
      <div class="mx-2">Add-Lab-Order-Items</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem = '4'" [ngClass]="{ active: showItem == '4' }">
      <mat-icon mat-list-icon>radiology</mat-icon>
      <div class="mx-2">Add-Radiology-Order-Items</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem = '6'" [ngClass]="{ active: showItem == '6' }">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View Refill Requests</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
      <mat-icon>sms</mat-icon>
      <div class="mx-2"> Send SMS</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
      <mat-icon>mail</mat-icon>
      <div class="mx-2"> Send Email</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
      <mat-icon>send</mat-icon>
      <div class="mx-2"> Send Fax</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <!-- <div class="ItemS" (click)="showItem = '5'" [ngClass]="{ active: showItem == '5' }">
      <mat-icon mat-list-icon>search</mat-icon>
      <div class="mx-2">Scan Patient</div>
      <mat-icon>navigate_next</mat-icon>
    </div> -->
    <div class="ItemS">
      <mat-icon mat-list-icon>search</mat-icon>
      <a routerLink="/PatientDataScanner">Scan Patient</a>
    </div>


    <button type="button" (click)="logout()" class="btn float-right mx-3 my-3 logout " color="mute" mat-raised-button>
      <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
      <span>Logout</span>
    </button>
  </mat-menu>


  <h2 class="text-white text-center"><img class="nurseimg" src="{{nurse?.NurseImage}}" alt=""> {{nurse?.NurseName}}
    <span class="text-white text-center"> - Id: {{nurse?.NurseId}}</span>
  </h2>

</div>


<span [ngSwitch]="showItem">


  <p *ngSwitchCase="'1'">

    <app-nurse-dashboard></app-nurse-dashboard>
  </p>
  <p *ngSwitchCase="'2'">

    <app-view-nurse-patients-by-hospital [isNurse]="true"
      (navigation)="navigateToNurseDashboard()"></app-view-nurse-patients-by-hospital>
  </p>


  <p *ngSwitchCase="'3'">

    <app-add-lab-order-subject></app-add-lab-order-subject>
  </p>
  <p *ngSwitchCase="'4'">

    <app-add-radiology-order-subject></app-add-radiology-order-subject>
  </p>
  <p *ngSwitchCase="'6'">

    <app-view-refill-request-by-nurse></app-view-refill-request-by-nurse>
  </p>

  <p *ngSwitchCase="'5'">

    <app-patient-data-scanner></app-patient-data-scanner>
  </p>
  <p *ngSwitchCase="'35'">
    <app-sms></app-sms>
  </p>
  <p *ngSwitchCase="'36'">
    <app-email></app-email>
  </p>
  <p *ngSwitchCase="'45'">
    <app-fax></app-fax>
  </p>

  <div *ngSwitchDefault>

    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>


      <button mat-raised-button color="primary" (click)="clearFromShift()">Remove</button>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>


          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
            <td mat-cell *matCellDef="let row"> <mat-checkbox class="example-margin"
                (change)="selectPatients($event, row)"></mat-checkbox>
            </td>
          </ng-container>


          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
          </ng-container>


          <ng-container matColumnDef="FirstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientFirstName}} </td>
          </ng-container>
          <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientLastName}} </td>
          </ng-container>
          <ng-container matColumnDef="Gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientGender}} </td>
          </ng-container>
          <ng-container matColumnDef="PatientNationalId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> National Id </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientNationalId}} </td>
          </ng-container>
          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td class="ml-4" mat-cell *matCellDef="let row"> {{row.Email}} </td>
          </ng-container>
          <ng-container matColumnDef="PhoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
            <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="PrimaryDoctor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Doctor </th>
            <td mat-cell *matCellDef="let row"> {{row.PrimaryDoctorsName}} </td>
          </ng-container>

          <ng-container matColumnDef="WardName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ward </th>
            <td mat-cell *matCellDef="let row"> {{row.WardName}} </td>
          </ng-container>

          <ng-container matColumnDef="Detail">
            <th mat-header-cell *matHeaderCellDef> Patient Details</th>
            <td mat-cell *matCellDef="let element">
              <button type="button" [routerLink]="['/nursePatient/',element.PatientId]" mat-mini-fab color="primary">
                <mat-icon matPrefix>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>

    </div>
  </div>







</span>

<!-- </div>
     </div> -->
<!-- </div> -->