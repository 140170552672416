<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">science</mat-icon>
            Lab Manager
        </ng-template>

        <div class="">

            <table class="table table w-100">
                <thead>
                    <th>FullName</th>
                    <th>D.O.B</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>NationalId</th>
                    <th>Address</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ph of labManagerList">
                        <td>{{ph.FullName}}</td>
                        <td>{{ph.Dob}}</td>
                        <td>{{ph.Email}}</td>
                        <td>{{ph.PhoneNumber}}</td>
                        <td>{{ph.NationalId}}</td>
                        <td>{{ph.Address}}</td>
                        <td>{{ph.Status}}</td>
                        <td>
                            <button (click)="delete(ph.LabManagerId)" mat-mini-fab>delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Lab Manager
        </ng-template>
        <app-add-lab-manager></app-add-lab-manager>
    </mat-tab>


</mat-tab-group>