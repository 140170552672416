<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <!-- *ngIf = "!currentPatient.IsInsuranceCompleted" -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      View Radiology report
    </ng-template>



    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportType">
          <th mat-header-cell *matHeaderCellDef> ReportType </th>
          <td mat-cell *matCellDef="let row"> {{row.ReportType}} </td>
        </ng-container>

        <ng-container matColumnDef="Description">
          <th class="message" mat-header-cell *matHeaderCellDef> Findings </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="OrderedBy">
          <th mat-header-cell *matHeaderCellDef> Ordered By </th>
          <td mat-cell *matCellDef="let row"> {{row.OrderedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="LabName">
          <th mat-header-cell *matHeaderCellDef> Radiology Lab Name </th>
          <td mat-cell *matCellDef="let row"> {{row.LabName}} </td>
        </ng-container>
        <ng-container matColumnDef="ReportedBy">
          <th mat-header-cell *matHeaderCellDef> Reported By </th>
          <td mat-cell *matCellDef="let row"> {{row.ReportedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="VerifiedBy">
          <th mat-header-cell *matHeaderCellDef> Verified By </th>
          <td mat-cell *matCellDef="let row"> {{row.VerifiedBy}} </td>
        </ng-container>

        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef> View Report </th>
          <td mat-cell *matCellDef="let row">
            <!-- modal  -->
            <button type="button" (click)="viewLabReport(row)" class="btn btn-primary" data-toggle="modal"
              data-target="#lab_report_modal">
              View Report
            </button>

            <div class="modal fade bd-example-modal-lg" id="lab_report_modal" tabindex="-1" role="dialog"
              aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Report
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body " id="printable">
                    <app-view-hospital></app-view-hospital>
                    <div class="areaStyle mb-1">
                      <P>Patient ID: {{patient?.PatientId}} </P>

                      <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                      <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                      <P>Patient Address: {{patient?.PatientAddress}} </P>
                    </div>
                    <div class="areaStyle mb-1 ">
                      <P>Date Of Report: {{row?.Date}}</P>
                      <P>Ordered By Doctor: {{row?.OrderedBy}}</P>
                      <P>ReportType: {{row?.ReportType}}</P>

                    </div>
                    <P class="areaStyle">Examination: {{row?.Examination}}</P>
                    <P class="areaStyle">Indication: {{row?.Indication}}</P>
                    <P class="areaStyle">Technique: {{row?.Technique}}</P>
                    <P class="areaStyle">Findings: {{row?.Description}}</P>
                    <P class="areaStyle">Impression: {{row?.Impression}}</P>
                    <div class="areaStyle">
                      <P>Lab Name: {{row?.LabName}}</P>
                      <P>Reported By : {{row?.ReportedBy}}</P>
                      <P>Reported By : {{row?.VerifiedBy}}</P>

                    </div>
                    <div [innerHTML]="radioDocument"></div>

                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      Close
                    </button>
                    <button type="button" (click)="print()" class="btn btn-secondary mx-3" data-dismiss="modal">
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- modal  -->
          </td>
        </ng-container>
        <ng-container matColumnDef="Update">
          <th mat-header-cell *matHeaderCellDef> Update</th>
          <td mat-cell *matCellDef="let element">
            <button type="button" [disabled]="CurrentDoctorId != element.DoctorId"
              [routerLink]="['/updateRadiologyReport/',element.RadiologyReportId]" mat-mini-fab color="primary">
              <mat-icon matPrefix>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> Delete</th>
          <td mat-cell *matCellDef="let element">
            <button [disabled]="CurrentDoctorId != element.DoctorId" (click)="delete(element.RadiologyReportId)"
              mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>


  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">upload</mat-icon>
      View Uploaded Document
    </ng-template>
    <!-- (submitAction)="handleSubmitAction()" -->
    <app-view-upload-radiology-report></app-view-upload-radiology-report>

  </mat-tab>
</mat-tab-group>