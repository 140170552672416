import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalHistory } from 'src/app/Models/medicalhistory.model';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-medical-history',
  templateUrl: './view-medical-history.component.html',
  styleUrls: ['./view-medical-history.component.css']
})
export class ViewMedicalHistoryComponent implements OnInit {

  id=0;
   medicalHistoryList: MedicalHistory[]=[];
    constructor(private route:ActivatedRoute,private patientService:PatientService,private medicalHistoryService:MedicalHistoryService) { }
  
    ngOnInit(): void {
  
      this.route.params.subscribe(res=>{
        this.id= res['id']
      })
  
      this.getPatientMedicalHistory(this.id)
    }
  
  
  
    getPatientMedicalHistory(id){
  this.patientService.getMedicalHistory(id).subscribe(res=>{
    this.medicalHistoryList = res.MedicalHistorys;
  })
    }
  
    print(){
      window.print();
    }


    delete(id){
      this.medicalHistoryService.deleteMedicalHistory(id).subscribe(res=>{
        this.getPatientMedicalHistory(this.id);
      })
    }
  }
  