import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NeurologyVisit } from 'src/app/Models/neurologyvisit.model';
import { Patient } from 'src/app/Models/patient.model';
import { NeurologyVisitService } from 'src/app/services/neurology-visit.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-neurology-visit',
  templateUrl: './view-neurology-visit.component.html',
  styleUrls: ['./view-neurology-visit.component.css'],
})
export class ViewNeurologyVisitComponent implements OnInit {
  id = 0;
  patient: Patient;
  neurologyVisitList: NeurologyVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  NeurologyVisitId: NeurologyVisit[];
  showItem = '';
  show = true;
  isHide: boolean;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private neurologyVisitService: NeurologyVisitService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getNeurologyVisits(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.NeurologyVisits.sort(function (x, y) {
        return y.NeurologyVisitId - x.NeurologyVisitId;
      });
      // this.patientVisitList = res.PatientVisits;
    });
  }

  printPage() {
    // window.print();
    document.execCommand('print');
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.neurologyVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.neurologyVisitService.deleteNeurologyVisit(id).subscribe((res) => {
        this.filteredList = this.neurologyVisitList.filter(
          (x) => x.NeurologyVisitId != id
        );
      });
    }
  }
}
