import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorInitialEvaluation } from 'src/app/Models/doctorinitialevaluation.model';
import { PurposefullRound } from 'src/app/Models/purposefullround.model';
import { DoctorInitialEvaluationService } from 'src/app/services/doctor-initial-evaluation.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-doctor-initial-evaluation',
  templateUrl: './view-doctor-initial-evaluation.component.html',
  styleUrls: ['./view-doctor-initial-evaluation.component.css'],
})
export class ViewDoctorInitialEvaluationComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  evaluationList: DoctorInitialEvaluation[] = [];
  CurrentDoctorId: number;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private doctorInitialEvaluationService: DoctorInitialEvaluationService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientInitialEvaluation(this.id);
  }

  getPatientInitialEvaluation(id) {
    this.patientService.getDoctorEvaluation(id).subscribe((res) => {
      this.evaluationList = res.DoctorInitialEvaluations;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.doctorInitialEvaluationService
      .deleteDoctorInitialEvaluation(id)
      .subscribe((res) => {
        this.getPatientInitialEvaluation(this.id);
      });
  }
}
