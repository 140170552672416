<!-- <mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab >
      <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">content_cut</mat-icon>
         Surgical History
        </ng-template> -->


<div class="ml-3" id="printable">
  <button class="float-right mr-4 mt-4" color="primary" (click)="print()" mat-mini-fab> <mat-icon>print</mat-icon>
  </button>
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8" id="printable">
  <table mat-table [dataSource]="dataSource" matSort>




    <ng-container matColumnDef="DateOfSurgery">
      <th mat-header-cell *matHeaderCellDef> Date Of Surgery </th>
      <td mat-cell *matCellDef="let row"> {{row.DateOfSurgery}} </td>
    </ng-container>
    <ng-container matColumnDef="SurgeyText">
      <th mat-header-cell *matHeaderCellDef> Type Of Surgery </th>
      <td mat-cell *matCellDef="let row"> {{row.SurgeyText}} </td>
    </ng-container>
    <ng-container matColumnDef="Description">
      <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
      <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
    </ng-container>


    <!-- <ng-container matColumnDef="Update">
              <th  mat-header-cell *matHeaderCellDef> Edit </th>
              <td mat-cell *matCellDef="let element"> 
                <button type="button"  [routerLink]="['/editInvoice/',element.InvoiceId]" mat-mini-fab color="primary">
                  <mat-icon matPrefix>edit</mat-icon>
                </button>
              </td>
            </ng-container>-->
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="isPrint" (click)="delete(element.SurgicalHistoryId)" mat-mini-fab
          color="accent"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- </mat-tab>
      

<mat-tab > 
  <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      Register Surgical History
    </ng-template>

    <app-add-surgical-history></app-add-surgical-history>
</mat-tab>


</mat-tab-group> -->