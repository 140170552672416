import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Hospital } from 'src/app/Models/hospital.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { getFilteredDate } from 'src/app/shared/utility';

@Component({
  selector: 'app-view-allhospitals',
  templateUrl: './view-allhospitals.component.html',
  styleUrls: ['./view-allhospitals.component.css'],
})
export class ViewAllhospitalsComponent implements OnInit {
  helper = new JwtHelperService();
  showItem = '';
  id = 0;
  myToken = window.localStorage.getItem('token');

  hospitalList: Hospital[];
  Frm: FormGroup;
  filteredList;
  PatientVisits: any = {};
  NewVisits: any = {};

  constructor(
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe(res=>{
    //   this.id = res['id'];
    // })

    this.getHospital();

    this.Frm = this.fb.group({
      HospitalId: [''],
    });
  }

  get HospitalId() {
    return this.Frm.get('HospitalId');
  }

  getHospital() {
    this.hospitalService.getHospitals().subscribe((res) => {
      this.hospitalList = res;
    });
  }

  // getHospitalById() {
  //   this.hospitalService
  //     .GetPatientVisits(this.HospitalId.value)
  //     .subscribe((res) => {
  //       this.filteredList = res.PhysicalTherapyVisits;
  //     });
  // }

  getAllPatientVisits(): void {
    this.hospitalService.GetPatientVisits(this.HospitalId.value).subscribe({
      next: (res) => {
        this.NewVisits = res.PatientVisits.filter((visit) =>
          this.visitsAddedInLast30Days(
            visit.createdDate,
            res.PatientVisits.map((p) => p.createdDate)
          )
        );
        const currentMonth = res.PatientVisits.filter((visit) => {
          const startDate = getFilteredDate(new Date(new Date().setDate(1)));
          const endDate = getFilteredDate(new Date(new Date().setDate(30)));
          if (
            new Date(visit.createdDate) <= new Date(endDate) ||
            new Date(visit.createdDate) >= new Date(startDate)
          ) {
            return visit;
          }
        });

        const fees = currentMonth
          .map((r) => r.SoftwareFee)
          .reduce((a, b) => Number(a) + Number(b), 0);
        this.PatientVisits['New'] = this.NewVisits.length;
        this.PatientVisits['SoftwareFee'] = fees ? fees : 0;

        // this.PatientVisits['NewActive'] = this.NewVisits.filter(
        //   (patient) => patient.SoftwareFee != null
        // ).length;
      },
    });
  }

  visitsAddedInLast30Days(date, patientVisits) {
    // const now = new Date();
    // const last30Days = now;
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 30);

    const existsInLast30Days = patientVisits.filter((patient) => {
      return (
        today.getTime() - new Date(patient).getTime() <=
        30 * 24 * 60 * 60 * 1000
      );
    });

    return existsInLast30Days.includes(date);
  }
}
