import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Hospital } from 'src/app/Models/hospital.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

@Component({
  selector: 'app-ptmedicalrecords',
  templateUrl: './ptmedicalrecords.component.html',
  styleUrls: ['./ptmedicalrecords.component.css'],
})
export class PtmedicalrecordsComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  show = false;
  myToken = window.localStorage.getItem('token');
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  patientVisitId: PatientVisit[];
  isHide = false;
  hospitalId: number;
  hospital: Hospital;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe(res=>{
    //   this.id= res['id']
    // })

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PatientVisits;
      this.patientVisitList = res.PatientVisits;

      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });
    this.getPatientById(this.id);
  }
  printPage() {
    window.print();
    // this.isHide = true;
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.VistitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  getPatientById(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;

      // console.log(this.isNewPatient);
    });
  }
}
