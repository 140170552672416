import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorInitialEvaluationService } from 'src/app/services/doctor-initial-evaluation.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-add-doctor-initial-evaluation',
  templateUrl: './add-doctor-initial-evaluation.component.html',
  styleUrls: ['./add-doctor-initial-evaluation.component.css'],
})
export class AddDoctorInitialEvaluationComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  id = 0;

  doctorId = 0;
  isSuccess = false;
  Frm: FormGroup;
  doctor: Doctor;
  patient: Patient;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private doctorEvaluationService: DoctorInitialEvaluationService,
    private router: Router,
    private doctorService: DoctorService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);
    this.getPatient(this.id);

    this.Frm = this.fb.group({
      PatientId: this.id,
      DoctorId: this.doctorId,
      PatientName: ['', Validators.required],
      PatientDOB: ['', Validators.required],
      PatientHeight: ['', Validators.required],
      PatientWeight: ['', Validators.required],
      BloodPressure: ['', Validators.required],
      Pulse: ['', Validators.required],
      IsPatientonAnyMedication: ['', Validators.required],
      IsPatientUnderAnyMedicalTreatment: ['', Validators.required],
      //Constitutional/Genaral
      RecentWeightChanges: [false],
      Chills: [false],
      Fever: [false],
      Weakness: [false],
      NoGeneralProblems: [false],
      OtherGeneralProblems: [''],
      //Eyes
      VisionChange: [false],
      GlassessOrContacts: [false],
      Catracts: [false],
      Glaucoma: [false],
      NoEyeProblems: [false],
      OtherEyeProblems: [''],
      //Ear,Nose,Throat
      HearingLoss: [false],
      EarAcheOrInfection: [false],
      RingingInEar: [false],
      NoEarProblems: [false],
      OtherEarProblems: [''],
      //Cardio Vascular
      ChestPain: [false],
      SwellingInLegs: [false],
      Palpitations: [false],
      NoCadioVascularProblems: [false],
      OtherCadioVascularProblems: [''],
      //Respiratory
      ShortnessOfBreath: [false],
      WheezingOrAsthma: [false],
      FrequentCough: [false],
      NoRespiratoryProblems: [false],
      OtherRespiratoryProblems: [''],
      //Gastrointestinal
      AcidReflex: [false],
      NauseaOrVomitimng: [false],
      AbdominalPain: [false],
      NoGastricProblems: [false],
      OtherGastricProblems: [''],
      MuscleAches: [false],
      SwellingOfJoints: [false],
      StiffnessInJoints: [false],
      NoMuskularProblems: [false],
      OtherMuskularProblems: [''],

      //Skin
      Rash: [false],
      Ulcers: [false],
      AbnormalScars: [false],
      NoSkinProblems: [false],
      OtherSkinProblems: [''],

      //Neurological

      Headaches: [false],
      Dizziness: [false],
      NumbnessTinglingLossofSensation: [false],
      NoNeurologicalProblems: [false],
      OtherNeurologicalProblems: [''],

      // Psychiatric
      Depression: [false],
      Nervousness: [false],
      Anxiety: [false],
      MoodSwings: [false],
      NoPsychatricProblems: [false],
      OtherPsychatricProblems: [''],
      //Endocrine
      ExessiveThirstOrHunger: [false],
      HotColdIntolerance: [false],
      HotFlashes: [false],
      NoEndocrine: [false],
      OtherEndocrine: [''],
      DoctorName: [''],
    });
  }

  get PatientName() {
    return this.Frm.get('PatientName');
  }
  get PatientDOB() {
    return this.Frm.get('PatientDOB');
  }
  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get PatientHeight() {
    return this.Frm.get('PatientHeight');
  }
  get PatientWeight() {
    return this.Frm.get('PatientWeight');
  }
  get BloodPressure() {
    return this.Frm.get('BloodPressure');
  }
  get Pulse() {
    return this.Frm.get('Pulse');
  }
  get IsPatientonAnyMedication() {
    return this.Frm.get('IsPatientonAnyMedication');
  }
  get IsPatientUnderAnyMedicalTreatment() {
    return this.Frm.get('IsPatientUnderAnyMedicalTreatment');
  }
  get RecentWeightChanges() {
    return this.Frm.get('RecentWeightChanges');
  }
  get Chills() {
    return this.Frm.get('Chills');
  }
  get Fever() {
    return this.Frm.get('Fever');
  }
  get Weakness() {
    return this.Frm.get('Weakness');
  }
  get NoGeneralProblems() {
    return this.Frm.get('NoGeneralProblems');
  }
  get OtherGeneralProblems() {
    return this.Frm.get('OtherGeneralProblems');
  }
  get VisionChange() {
    return this.Frm.get('VisionChange');
  }
  get GlassessOrContacts() {
    return this.Frm.get('GlassessOrContacts');
  }
  get Catracts() {
    return this.Frm.get('Catracts');
  }
  get Glaucoma() {
    return this.Frm.get('Glaucoma');
  }
  get NoEyeProblems() {
    return this.Frm.get('NoEyeProblems');
  }
  get OtherEyeProblems() {
    return this.Frm.get('OtherEyeProblems');
  }
  get HearingLoss() {
    return this.Frm.get('HearingLoss');
  }
  get EarAcheOrInfection() {
    return this.Frm.get('EarAcheOrInfection');
  }
  get RingingInEar() {
    return this.Frm.get('RingingInEar');
  }
  get NoEarProblems() {
    return this.Frm.get('NoEarProblems');
  }
  get OtherEarProblems() {
    return this.Frm.get('OtherEarProblems');
  }
  get ChestPain() {
    return this.Frm.get('ChestPain');
  }
  get SwellingInLegs() {
    return this.Frm.get('SwellingInLegs');
  }
  get Palpitations() {
    return this.Frm.get('Palpitations');
  }
  get NoCadioVascularProblems() {
    return this.Frm.get('NoCadioVascularProblems');
  }
  get OtherCadioVascularProblems() {
    return this.Frm.get('OtherCadioVascularProblems');
  }
  get ShortnessOfBreath() {
    return this.Frm.get('ShortnessOfBreath');
  }
  get WheezingOrAsthma() {
    return this.Frm.get('WheezingOrAsthma');
  }
  get FrequentCough() {
    return this.Frm.get('FrequentCough');
  }
  get NoRespiratoryProblems() {
    return this.Frm.get('NoRespiratoryProblems');
  }
  get OtherRespiratoryProblems() {
    return this.Frm.get('OtherRespiratoryProblems');
  }
  get AcidReflex() {
    return this.Frm.get('AcidReflex');
  }
  get NauseaOrVomitimng() {
    return this.Frm.get('NauseaOrVomitimng');
  }
  get AbdominalPain() {
    return this.Frm.get('AbdominalPain');
  }
  get NoGastricProblems() {
    return this.Frm.get('NoGastricProblems');
  }
  get OtherGastricProblems() {
    return this.Frm.get('OtherGastricProblems');
  }
  get MuscleAches() {
    return this.Frm.get('MuscleAches');
  }
  get NoMuskularProblems() {
    return this.Frm.get('NoMuskularProblems');
  }

  get OtherMuskularProblems() {
    return this.Frm.get('OtherMuskularProblems');
  }

  get Rash() {
    return this.Frm.get('Rash');
  }

  get Ulcers() {
    return this.Frm.get('Ulcers');
  }

  get AbnormalScars() {
    return this.Frm.get('AbnormalScars');
  }

  get NoSkinProblems() {
    return this.Frm.get('NoSkinProblems');
  }

  get OtherSkinProblems() {
    return this.Frm.get('OtherSkinProblems');
  }

  get Headaches() {
    return this.Frm.get('Headaches');
  }

  get Dizziness() {
    return this.Frm.get('Dizziness');
  }

  get NumbnessTinglingLossofSensation() {
    return this.Frm.get('NumbnessTinglingLossofSensation');
  }

  get NoNeurologicalProblems() {
    return this.Frm.get('NoNeurologicalProblems');
  }

  get OtherNeurologicalProblems() {
    return this.Frm.get('OtherNeurologicalProblems');
  }

  get Depression() {
    return this.Frm.get('Depression');
  }

  get Nervousness() {
    return this.Frm.get('Nervousness');
  }

  get Anxiety() {
    return this.Frm.get('Anxiety');
  }
  get MoodSwings() {
    return this.Frm.get('MoodSwings');
  }
  get NoPsychatricProblems() {
    return this.Frm.get('NoPsychatricProblems');
  }
  get OtherPsychatricProblems() {
    return this.Frm.get('OtherPsychatricProblems');
  }
  get ExessiveThirstOrHunger() {
    return this.Frm.get('ExessiveThirstOrHunger');
  }
  get HotColdIntolerance() {
    return this.Frm.get('HotColdIntolerance');
  }
  get HotFlashes() {
    return this.Frm.get('HotFlashes');
  }
  get NoEndocrine() {
    return this.Frm.get('NoEndocrine');
  }
  get OtherEndocrine() {
    return this.Frm.get('OtherEndocrine');
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  formSubmit() {
    let body = {
      ...this.Frm.value,
      DoctorName: this.doctor.DoctorName,
      PatientName: this.patient.PatientFirstName + this.patient.PatientLastName,
      PatientDOB: this.patient.PatientDOB,
      PatientHeight: this.patient.PatientHeight,
      PatientWeight: this.patient.PatientWeight,
    };
    // console.log(body);
    this.doctorEvaluationService
      .registerDoctorInitialEvaluation(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
