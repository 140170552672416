import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-radiology-patient-scanner',
  templateUrl: './radiology-patient-scanner.component.html',
  styleUrls: ['./radiology-patient-scanner.component.css']
})
export class RadiologyPatientScannerComponent implements OnInit {





  constructor(private patientService:PatientService,private route:ActivatedRoute,private router:Router) {
 
  }
 
  ngOnInit(): void {
 
  }
 
 // https://www.npmjs.com/package/angularx-qrcode
 qrCodeString = 'This is a secret qr code message';
 scannedResult: any;
 content_visibility = '';
 
 
 
 
 
 async checkPermission() {
   try {
     // check or request permission
     const status = await BarcodeScanner.checkPermission({ force: true });
     if (status.granted) {
       // the user granted permission
       return true;
     }
     return false;
   } catch(e) {
     console.log(e);
   }
 }
 
 async startScan() {
   try {
     const permission = await this.checkPermission();
     if(!permission) {
       return;
     }
     await BarcodeScanner.hideBackground();
     document.querySelector('body').classList.add('scanner-active');
     this.content_visibility = 'hidden';
     const result = await BarcodeScanner.startScan();
     // console.log(result);
     BarcodeScanner.showBackground();
     document.querySelector('body').classList.remove('scanner-active');
     this.content_visibility = '';
     if(result?.hasContent) {
       this.scannedResult = result.content;
       const patientId= parseInt(this.scannedResult)
       this.router.navigate(['/radiologyPatient/',patientId])
 
     
     }
   } catch(e) {
     console.log(e);
     this.stopScan();
   }
 }
 
 stopScan() {
   BarcodeScanner.showBackground();
   BarcodeScanner.stopScan();
   document.querySelector('body').classList.remove('scanner-active');
   this.content_visibility = '';
 }
 
 ngOnDestroy(): void {
     this.stopScan();
 }
 
 
 
 
 
 
   
  
 
 
 }