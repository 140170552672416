import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../Models/patient.model';
import { PatientInsurance } from '../../Models/patientinsurance.model';
import { EmergencyContact } from '../../Models/emergencycontact.models';
import { Allergy } from '../../Models/allergy.models';
import { SurgicalHistory } from '../../Models/surgicalhistory.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { Labreport } from 'src/app/Models/labreport.model';
import { AppointmentService } from '../../services/appointment.service';
import { tap } from 'rxjs/operators';
import { Appointment } from 'src/app/Models/appointment.model';
import { PatientIdProof } from 'src/app/Models/patientidproof.model';
import { PastMedication } from 'src/app/Models/pastmedication.model';
import { MedicalHistory } from 'src/app/Models/medicalhistory.model';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
@Component({
  selector: 'app-patientsummary',
  templateUrl: './patientsummary.component.html',
  styleUrls: ['./patientsummary.component.css'],
})
export class PatientsummaryComponent implements OnInit {
  id = 0;
  content_visibility = '';
  Patient: Patient;
  Insurance: PatientInsurance[] = [];
  emContacts: EmergencyContact[] = [];
  allergies: Allergy[] = [];
  surgeries: SurgicalHistory[] = [];
  prescriptions: Prescription[] = [];
  labReport: Labreport[] = [];
  upcomingAppointment;

  displayStyle = 'none';
  labDocument: string = '';
  PatientId: string = '';
  PatientIdProof: PatientIdProof[];
  displayStyle1 = 'none';
  pastMedications: PastMedication[];
  medicalHistoryList: MedicalHistory[];
  pharmacy: PatientsPharmacy[];

  openPopup() {
    this.displayStyle = 'block';
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
  openPopFront() {
    this.displayStyle1 = 'block';
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
  closePopup() {
    this.displayStyle = 'none';
  }
  closePopFront() {
    this.displayStyle1 = 'none';
  }

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.PatientId = this.id.toString();
    this.getPatientById(this.id);
    this.getPtInsurance(this.id);
    this.getEmContact(this.id);
    this.getIdProof(this.id);
    this.getAllergyies(this.id);
    this.getSurgeries(this.id);
    this.getPrescription(this.id);
    this.getPastMedication(this.id);
    this.getPatientMedicalHistory(this.id);

    this.GetlabReports(this.id);
    this.getPatientAppointments(this.id);
    this.getPharmacy(this.id);
  }

  getPatientById(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.Patient = res;
    });
  }

  getPtInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.Insurance = res.PatientInsurances;
    });
  }
  getEmContact(id) {
    this.patientService.getPatientEMcontact(id).subscribe((res) => {
      this.emContacts = res.EmergencyContacts;
    });
  }
  getIdProof(id) {
    this.patientService.getPatientIdProof(id).subscribe((res) => {
      this.PatientIdProof = res.PatientIdProofs;
    });
  }
  getAllergyies(id) {
    this.patientService.getPatientAllergy(id).subscribe((res) => {
      this.allergies = res.Allergies;
    });
  }
  getSurgeries(id) {
    this.patientService.getPatientsurgery(id).subscribe((res) => {
      this.surgeries = res.SurgicalHistories;
    });
  }
  getPrescription(id) {
    this.patientService.getPatientPrescription(id).subscribe((res) => {
      this.prescriptions = res.Prescriptions.sort((x, y) => {
        return y.PrescriptionId - x.PrescriptionId;
      });
    });
  }
  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
    });
  }
  GetlabReports(id) {
    this.patientService.getPatienLabReport(id).subscribe((res) => {
      this.labReport = res.LabReports;
    });
  }

  getPastMedication(id) {
    this.patientService.GetPastMedication(id).subscribe((res) => {
      // this.PastMedicationList = res;

      this.pastMedications = res.PastMedications;
    });
  }
  getPatientMedicalHistory(id) {
    this.patientService.getMedicalHistory(id).subscribe((res) => {
      this.medicalHistoryList = res.MedicalHistorys;
    });
  }
  private getPatientAppointments(id: number | string): void {
    this.appointmentService.getPatientBookings().subscribe((res) => {
      let upcomingDate = null;
      const date = new Date();
      res.forEach((data) => {
        if (
          new Date(data.VisitDate).getTime() > date.getTime() &&
          (!upcomingDate || date.getTime() < upcomingDate.getTime())
        ) {
          this.upcomingAppointment = data;
        }
      });
    });
  }

  public viewLabReport(report: Labreport): void {
    // console.log('report: ', report);
    this.labDocument = report.Document;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }

  print() {
    window.print();
  }
  printPage() {
    window.print();
  }

  postData(data) {
    this.router.navigateByUrl('/addRefillRequest/data');
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }
}
