<div class="container ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Neurology Visit</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <!-- <mat-form-field class="example-full-width">
                    <mat-label>Report Name</mat-label>
                    <input type="text" formControlName="ReportName" matInput >
                    <mat-error>Please enter Report Name</mat-error>
                  </mat-form-field> -->
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Chief Complaints:</mat-label>

                            <textarea matInput formControlName="ChiefComplaint" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Present Illness:</mat-label>

                            <textarea matInput formControlName="PresentIllness" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <h4> Past History</h4>
                <div class="row">
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Medical:</mat-label>

                            <textarea matInput formControlName="Medical" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Surgical:</mat-label>

                            <textarea matInput formControlName="Surgical" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Allergies:</mat-label>

                            <textarea matInput formControlName="Allergies" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Medications:</mat-label>

                            <textarea matInput formControlName="Medications" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Social History:</mat-label>

                            <textarea matInput formControlName="SocialHistory" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Family History:</mat-label>

                            <textarea matInput formControlName="FamilyHistory" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Review of Symptoms:</mat-label>

                            <textarea matInput formControlName="ReviewOfSymptoms" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <h4>Physical Examinations</h4>
                <div class="row">
                    <div class="col-lg-12">

                        <mat-form-field class="w-100">
                            <mat-chip-list>
                                <ng-container *ngIf="selectedCPTCodes.length">
                                    <mat-chip *ngFor="let item of selectedCPTCodes" (removed)="removeOption(item)">
                                        {{ item }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip></ng-container>
                                <mat-label>Diagnosis:</mat-label>

                                <input type="text" placeholder="Type code to search" aria-label="Diagnosis Codes"
                                    matInput [formControl]="CptCode" [matAutocomplete]="auto" />
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addOption($event)">

                                    <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                                        <mat-option *cdkVirtualFor="let entry of filteredValues" [value]="entry.Code">
                                            {{entry.Code}}
                                        </mat-option>
                                    </cdk-virtual-scroll-viewport>
                                </mat-autocomplete>
                            </mat-chip-list>
                        </mat-form-field>



                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Vital Signs:</mat-label>

                            <textarea matInput formControlName="VitalSigns" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Head, Eyes, Ears, Nose and Throat:</mat-label>

                            <textarea matInput formControlName="HENT" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Neck:</mat-label>

                            <textarea matInput formControlName="Neck" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Chest:</mat-label>

                            <textarea matInput formControlName="Chest" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Heart:</mat-label>

                            <textarea matInput formControlName="Heart" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Abdomen:</mat-label>

                            <textarea matInput formControlName="Abdomen" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Extermities:</mat-label>

                            <textarea matInput formControlName="Extermities" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Neurological:</mat-label>

                            <textarea matInput formControlName="Neurological" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Radiology(X-rays/MRI's):</mat-label>

                            <textarea matInput formControlName="Radiology" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Impression:</mat-label>

                            <textarea matInput formControlName="Impression" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <mat-form-field class="example-full-width">
                            <mat-label>Recomendations:</mat-label>

                            <textarea matInput formControlName="Recomendation" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4"><mat-form-field class="example-full-width">
                            <mat-label>Doctor Name</mat-label>
                            <input type="text" formControlName="DoctorName" matInput>
                            <mat-error>Please enter Report Name</mat-error>
                        </mat-form-field></div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Signature</mat-label>
                            <input type="text" formControlName="Signature" matInput>
                            <mat-error>Please enter Signature</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date</mat-label>
                            <input type="date" formControlName="Date" matInput>
                            <mat-error>Please enter Date</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>