<div class="toolbar mat-elevation-z6">

  <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">
    <!-- <div class="ItemS" >
                <mat-icon mat-list-icon>undo</mat-icon>
                <div class="mx-2" [routerLink]="['/labDash/']"> Lab Dashboard</div>
              </div> -->



    <div class="ItemS " (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>dashboard</mat-icon>
      <div class="mx-2">Lab Dashboard</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
      <mat-icon mat-list-icon>science</mat-icon>
      <div class="mx-2">Lab Orders</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <!-- <div class="ItemS " (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
      <mat-icon mat-list-icon>search</mat-icon>
      <div class="mx-2">Scan Patient</div>
      <mat-icon>navigate_next</mat-icon>
    </div> -->
    <div class="ItemS">
      <mat-icon mat-list-icon>search</mat-icon>
      <a routerLink="/labPatientScanner">Scan Patient</a>
    </div>


    <button type="button" (click)="logout()" class="btn float-right mx-3 my-3 logout " color="mute" mat-raised-button>
      <span>Logout</span>
    </button>

  </mat-menu>


</div>


<span [ngSwitch]="showItem">

  <p *ngSwitchCase="'1'">
    <app-view-all-lab-orders></app-view-all-lab-orders>
  </p>

  <p *ngSwitchCase="'2'">
    <app-labdashboard></app-labdashboard>
  </p>

  <p *ngSwitchCase="'3'">
    <app-lab-patient-scanner></app-lab-patient-scanner>
  </p>

  <div *ngSwitchDefault>


    <div class="container mt-2">
      <div class="row">
        <div class="col-lg-6 "><span class="float-right"></span></div>
        <div class="col-lg-6 ">
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Search by National Id</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <h2 class="ml-5 mb-3">Patients</h2><br>

      <table class="table table">

        <thead>
          <th></th>
          <th>First Name</th>
          <th>Last Name </th>
          <th>National Id</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Primary Doctor</th>
          <th>Patient Lab Reports</th>
          <!-- <th>Delete</th> -->

        </thead>
        <tbody>

          <tr *ngFor="let item of filteredList">
            <td></td>
            <td>{{item.PatientFirstName}}</td>

            <td>{{item.PatientLastName}}</td>
            <td>{{item.PatientNationalId}}</td>
            <td>{{item.PhoneNumber}}</td>
            <td>{{item.Email}}</td>
            <td>{{item.PrimaryDoctorsName}}</td>

            <td>

              <button [routerLink]="['/labPatient/',item.PatientId]" mat-mini-fab color="primary">
                <!-- <img src="../../../assets/show.png" class="image" style="max-width: 30px;" alt="">  -->
                <mat-icon mat-list-icon>visibility</mat-icon>
              </button>

            </td>




          </tr>
        </tbody>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </div>







</span>