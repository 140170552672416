import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FamilyhistoryService } from 'src/app/services/familyhistory.service';

@Component({
  selector: 'app-add-familyhistory',
  templateUrl: './add-familyhistory.component.html',
  styleUrls: ['./add-familyhistory.component.css']
})
export class AddFamilyhistoryComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  
  parent: string;
  whohadproblems: string[] = ['Father', 'Mother'];
  id=0;
  isSuccess= false;
  Frm: FormGroup;
  constructor(private fb:UntypedFormBuilder,private familyHistoryService:FamilyhistoryService,private route:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {

    this.route.params.subscribe(res=>{

      this.id= res['id'];
    })

    
  this.Frm = this.fb.group({
    PatientId: this.id,
    AnesthesiaProblems:[false],
    WhoHadAnesthesiaProblems:[''],
    Arthritis:[false],
    WhoHadArthritis:[''],
    Cancer:[false],
    WhoHadCancer:[''],
    Diabetes:[false],
    WhoHadDiabetes:[''],
    HeartProblems:[false],
    WhoHadHeartProblems:[''],
    Hypertension:[false],
    WhoHadHypertension:[''],
    Stroke:[false],
    WhoHadStroke:[''],
    ThyroidDisorder:[false],
    WhoHadThyroidDisorder:[''],
    Problem : [''],
    WhoHadIt:[''],
    Description:['']
  })
  }





  get PatientId(){
    return this.Frm.get('PatientId')
  }

get AnesthesiaProblems(){
  return this.Frm.get('AnesthesiaProblems')
}

get WhoHadAnesthesiaProblems(){
  return this.Frm.get('WhoHadAnesthesiaProblems')
}


get Arthritis(){
  return this.Frm.get('Arthritis')
}

get WhoHadArthritis(){
  return this.Frm.get('WhoHadArthritis')
}

get Cancer(){
  return this.Frm.get('Cancer')
}

get WhoHadCancer(){
  return this.Frm.get('WhoHadCancer')
}

get Diabetes(){
  return this.Frm.get('Diabetes')
}

get WhoHadDiabetes(){
  return this.Frm.get('WhoHadDiabetes')
}
get HeartProblems(){
  return this.Frm.get('HeartProblems')
}

get WhoHadHeartProblems(){
  return this.Frm.get('WhoHadHeartProblems')
}
get Hypertension(){
  return this.Frm.get('Hypertension')
}

get WhoHadHypertension(){
  return this.Frm.get('WhoHadHypertension')
}
get Stroke(){
  return this.Frm.get('Stroke')
}

get WhoHadStroke(){
  return this.Frm.get('WhoHadStroke')
}
get ThyroidDisorder(){
  return this.Frm.get('ThyroidDisorder')
}

get WhoHadThyroidDisorder(){
  return this.Frm.get('WhoHadThyroidDisorder')
}

get Problem(){
  return this.Frm.get('Problem')
}
get WhoHadIt(){
  return this.Frm.get('WhoHadIt')
}
get Description(){
  return this.Frm.get('Description')
}






  formSubmit(){

this.familyHistoryService.registerFamilyHistory(this.Frm.value).subscribe(res=>{
  this.isSuccess=true
  this.Frm.reset();
  this.submitAction.emit("")
})
  }
}
