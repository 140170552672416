<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex Date, name" #input>
              </mat-form-field>
        </div>
    </div>
</div>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
  
    
      <ng-container matColumnDef="NurseAssessmentId">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef>Nurse Assessment Id </th>
        <td mat-cell *matCellDef="let row"> {{row.NurseAssessmentId}} </td>
      </ng-container>

      <ng-container matColumnDef="BedNo">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef>Bed No. </th>
        <td mat-cell *matCellDef="let row"> {{row.BedNo}} </td>
      </ng-container>
      
      <ng-container matColumnDef="Department">
        <th mat-header-cell *matHeaderCellDef > Department </th>
        <td mat-cell *matCellDef="let row"> {{row.Department}} </td>
      </ng-container>

      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef > Date </th>
        <td mat-cell *matCellDef="let row"> 22/03/10</td>
      </ng-container>
      <ng-container matColumnDef="View">
        <th  mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let element"> 
            <button 
            mat-mini-fab color="primary"><mat-icon [routerLink]="[ '/viewNursingAssessmentPlanById', element.NurseAssessmentId ]">visibility</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  