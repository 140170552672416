import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LabOrder } from 'src/app/Models/laborder.model';
import { LabOrderService } from 'src/app/services/lab-order.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-all-lab-orders',
  templateUrl: './view-all-lab-orders.component.html',
  styleUrls: ['./view-all-lab-orders.component.css']
})
export class ViewAllLabOrdersComponent implements OnInit {

  
  displayedColumns: string[] = ['Date','OrderedBy', 'Subject', 'Description','Delete'];

  id=0;
labOrder:LabOrder[]=[];
dataSource: MatTableDataSource<any>;
labDocument: string = '';


  constructor(private patientService:PatientService,private labOrderservice:LabOrderService,private route:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id= res['id'];
    })

    this.GetlabOrders();

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

 



  GetlabOrders(){
this.labOrderservice.getLabOrders().subscribe(res=>{
  
  this.labOrder = res

  this.dataSource = new MatTableDataSource(res);
  this.dataSource.paginator=this.paginator;

})
  }
  delete(id){
    this.labOrderservice.deleteLabOrder(id).subscribe(res=>{
      // this.labOrder = this.labOrder.filter(x=>x.LabOrderId !=id)
      
      this.GetlabOrders();
    })
  }
  view(){

  }

  
 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

public viewLabReport(report: LabOrder): void {
  // console.log('report: ', report);
  this.labDocument = report.Description;
}
}

