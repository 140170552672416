import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { TimeslotService } from 'src/app/services/timeslot.service';
import { DoctordatesService } from '../../services/doctordates.service';
import * as moment from 'moment';
import { HospitalService } from 'src/app/services/hospital.service';
import { JwtHelperService } from '@auth0/angular-jwt';
@Component({
  selector: 'app-add-doctor-dates',
  templateUrl: './add-doctor-dates.component.html',
  styleUrls: ['./add-doctor-dates.component.css'],
})
export class AddDoctorDatesComponent implements OnInit {
  doctorList: Doctor[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  id = 0;
  myToken = window.localStorage.getItem('token');
  constructor(
    private fb: UntypedFormBuilder,
    private doctorDatesService: DoctordatesService,
    private hospitalService: HospitalService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getDoctors(this.id);
  }

  timFrm = this.fb.group({
    AvailableDate: ['', Validators.required],
    DoctorName: [''],
    DoctorId: ['', Validators.required],
  });

  get DoctorName() {
    return this.timFrm.get('DoctorName');
  }
  get AvailableDate() {
    return this.timFrm.get('AvailableDate');
  }
  get DoctorId() {
    return this.timFrm.get('DoctorId');
  }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }

  formSubmit() {
    let availableDate = this.timFrm.value['AvailableDate'];
    availableDate = moment(availableDate).format('MM/DD/YYYY');
    let doctorId = this.timFrm.value['DoctorId'];
    let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
      .DoctorName;

    let body = {
      AvailableDate: availableDate,
      DoctorId: doctorId,
      DoctorName: idoctorName,
    };

    this.doctorDatesService.registerDoctorDate(body).subscribe((res) => {
      this.isSuccess = true;
      this.timFrm.reset();
    });
  }
}
