<form [formGroup]="Frm" class="example-form">
    <div class="container mt-4">
        <div class="row ">
            <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                    <mat-label>Ward Name</mat-label>
                    <mat-select formControlName="WardId" (selectionChange)="getDutyRoster()">
                        <mat-option *ngFor="let ward of wardList" [value]="ward.WardId">{{
                            ward.WardName }}</mat-option>
                    </mat-select>
                    <mat-error>Select a Ward</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="ChargeNurse">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ChargeNurse </th>
            <td mat-cell *matCellDef="let row"> {{row.ChargeNurse}} </td>
        </ng-container>




        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View Duty Roster By Shift </th>
            <td mat-cell *matCellDef="let row">
                <button type="button" color="primary" mat-mini-fab data-toggle="modal"
                    data-target=".bd-example-modal-lg">
                    <mat-icon>report</mat-icon>
                </button>

                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">View Duty Roster By Shift </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body " id="printable">
                                <app-view-hospital></app-view-hospital>

                                <div class="row areaStyle mb-1">
                                    <div class="col-md-4">
                                        Date: {{row.createdDate | date:'medium'}}
                                    </div>
                                    <div class="col-md-4">
                                        <P>Charge Nurse: {{row?.ChargeNurse}} </P>
                                    </div>
                                    <div class="col-md-4">
                                        <P>Unit Secretory: {{row?.UnitSecretory}} </P>
                                    </div>

                                    <hr>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <P>Shift: {{row.Shift}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>MT: {{row.MT}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Census: {{row.Census}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Sitters: {{row.Sitters}}</P>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <P>CentralLines: {{row.CentralLines}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Inmates: {{row.Inmates}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>DNR: {{row.DNR}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>PendingTransfer: {{row.PendingTransfer}}</P>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <P>PendingDC: {{row.PendingDC}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Foleys: {{row.Foleys}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Restraints: {{row.Restraints}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Isolations: {{row.Isolations}}</P>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <P>VacantBeds: {{row.VacantBeds}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Falls: {{row.Falls}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Bariatrics: {{row.Bariatrics}}</P>

                                    </div>
                                    <div class="col-md-3">
                                        <P>Plastics: {{row.Plastics}}</P>

                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div>
                                <table class="table table">
                                    <thead>

                                        <th>Phones</th>
                                        <th>Staff Names</th>
                                        <th>Profile</th>
                                        <th>Assignments</th>
                                        <th>Admission</th>
                                        <th>Discharge</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rw of row.DutyRosterItems">
                                            <td>{{rw.Phones}}</td>
                                            <td>{{rw.Names}}</td>
                                            <td>{{rw.Profile}}</td>
                                            <td>{{rw.Assignments}}</td>
                                            <td>{{rw.Admission}}</td>
                                            <td>{{rw.Discharge}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="modal-footer">
                                <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                                <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef> Edit</th>
            <td mat-cell *matCellDef="let element">
                <button type="button" [routerLink]="['/updatePatient/',element.PatientId]" mat-mini-fab color="primary">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element">
                <button type="button" (click)="deletePatient(element.PatientId)" mat-mini-fab color="accent">
                    <mat-icon matPrefix>delete</mat-icon>
                </button>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>