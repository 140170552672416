import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Hospital } from 'src/app/Models/hospital.model';
import { OfficePolicy } from 'src/app/Models/officepolicy.model';
import { OfficePolicyService } from 'src/app/services/office-policy.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-office-policy-consent',
  templateUrl: './view-office-policy-consent.component.html',
  styleUrls: ['./view-office-policy-consent.component.css'],
})
export class ViewOfficePolicyConsentComponent implements OnInit {
  id = 0;
  officePolicyList: OfficePolicy[] = [];
  hospital: Hospital;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private officePolicyService: OfficePolicyService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.getOfficePolicy(this.id);
  }

  getOfficePolicy(id) {
    this.patientService.GetOfficePolicy(id).subscribe((res) => {
      this.officePolicyList = res.OfficePolicys;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.officePolicyService.deleteOfficePolicy(id).subscribe((res) => {
      this.getOfficePolicy(this.id);
    });
  }
}
