<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">local_pharmacy</mat-icon>
            Pharmacy
        </ng-template>
        <div class="container">

            <table class="table table">
                <thead>
                    <th>Pharmacy Name</th>
                    <!-- <th>Email</th> -->
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ph of phList">
                        <td>{{ph.PharmacyName}}</td>
                        <!-- <td>{{ph.Email}}</td> -->
                        <td>{{ph.PhoneNumber}}</td>
                        <td>{{ph.PharmacyAddress}}</td>
                        <td>{{ph.Status ?'Current':'Old'}}</td>
                        <td>
                            <button (click)="delete(ph.PatientsPharmacyId)"
                                mat-mini-fab><mat-icon>delete</mat-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Pharmacy
        </ng-template>
        <app-add-patient-pharmacy></app-add-patient-pharmacy>
    </mat-tab>


</mat-tab-group>