<mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">warning</mat-icon>
            Immunization Records
        </ng-template>

        <div class="ml-3">

            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class=" mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>


                <ng-container matColumnDef="VaccineName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Vaccine Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.VaccineName}} </td>
                </ng-container>


                <ng-container matColumnDef="VaccineProvider">
                    <th mat-header-cell *matHeaderCellDef> Vaccine Provider </th>
                    <td mat-cell *matCellDef="let row"> {{row.VaccineProvider}} </td>
                </ng-container>
                <ng-container matColumnDef="DateTaken">
                    <th mat-header-cell *matHeaderCellDef> Date Taken </th>
                    <td mat-cell *matCellDef="let row"> {{row.DateTaken}} </td>
                </ng-container>

                <ng-container matColumnDef="Description">
                    <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
                    <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
                </ng-container>

                <ng-container matColumnDef="View">
                    <th mat-header-cell *matHeaderCellDef> View Report </th>
                    <td mat-cell *matCellDef="let row">
                        <button type="button" color="primary" (click)="viewLabReport(row)" mat-mini-fab
                            data-toggle="modal" data-target=".bd-example-modal-lg">
                            <mat-icon>report</mat-icon>
                        </button>

                        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Report </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body " id="printable">
                                        <app-view-hospital></app-view-hospital>

                                        <div class="areaStyle mb-1">
                                            <P>Patient ID: {{patient?.PatientId}} </P>

                                            <P>Patient Name: {{patient?.PatientFirstName}} &nbsp;
                                                {{patient?.PatientLastName}} </P>
                                            <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                                            <P>Patient Address: {{patient?.PatientAddress}} </P>
                                            <hr>
                                        </div>

                                    </div>
                                    <hr>
                                    <div>
                                        <table class="table table-striped">
                                            <thead>

                                                <th>Vaccine Name</th>
                                                <th>Vaccine Provider</th>
                                                <th>DateTaken</th>
                                                <th>Description</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> {{VaccineName}}</td>
                                                    <td>{{VaccineProvider}}</td>
                                                    <td>{{DateTaken}}</td>
                                                    <td>{{Description}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                                        <button type="button" class="btn btn-secondary mx-3"
                                            data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </td>
                </ng-container>

                <!-- <ng-container matColumnDef="Update">
                  <th  mat-header-cell *matHeaderCellDef> Edit </th>
                  <td mat-cell *matCellDef="let element"> 
                    <button type="button"  [routerLink]="['/editAllergy/',element.CptCodeId]" mat-mini-fab color="primary">
                      <mat-icon matPrefix>edit</mat-icon>
                    </button>
                  </td>
                </ng-container> -->
                <!-- <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef> Delete</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="delete(element.VaccineId)" mat-mini-fab
                            color="accent"><mat-icon>delete</mat-icon></button>
                    </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Add Immunization Record
        </ng-template>

        <app-add-vaccine></app-add-vaccine>
    </mat-tab>


</mat-tab-group>