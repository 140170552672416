import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctorService } from '../../services/doctor.service';
import { UserService } from '../../services/user.service';
import { DepartmentService } from '../../services/department.service';
import { Department } from 'src/app/Models/department.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HospitalService } from 'src/app/services/hospital.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.css'],
})
export class AddDoctorComponent implements OnInit {
  id = 0;
  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];
  dptList: Department[];
  imageUrl: string;

  isSuccess = false;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;

  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  hospitalName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private userService: UserService,
    private router: Router,
    private dptService: DepartmentService,
    private http: HttpClient,
    private hosptalService: HospitalService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDepartments(this.id);

    this.countryCodes();
    this.filteredOptions =
      this.doctorFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  doctorFrm = this.fb.group({
    DepartmentId: [''],
    DoctorName: ['', Validators.required],
    LicenceNumber: [''],
    NpiNumber: [''],
    DoctorQualification: ['', Validators.required],
    DoctorSpecialization: ['', Validators.required],
    DoctorDOB: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    DoctorAddress: ['', Validators.required],
    StartDate: [''],
    EndDate: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
    Status: [true],
    DepartmentName: [''],
  });

  get LicenceNumber() {
    return this.doctorFrm.get('LicenceNumber');
  }
  get NpiNumber() {
    return this.doctorFrm.get('NpiNumber');
  }
  get CountryCode() {
    return this.doctorFrm.get('CountryCode');
  }

  get DepartmentName() {
    return this.doctorFrm.get('DepartmentName');
  }
  get Password() {
    return this.doctorFrm.get('Password');
  }
  get Email() {
    return this.doctorFrm.get('Email');
  }
  get DepartmentId() {
    return this.doctorFrm.get('DepartmentId');
  }
  get DoctorName() {
    return this.doctorFrm.get('DoctorName');
  }
  get DoctorQualification() {
    return this.doctorFrm.get('DoctorQualification');
  }
  get DoctorSpecialization() {
    return this.doctorFrm.get('DoctorSpecialization');
  }
  get DoctorDOB() {
    return this.doctorFrm.get('DoctorDOB');
  }
  get DoctorAddress() {
    return this.doctorFrm.get('DoctorAddress');
  }
  get PhoneNumber() {
    return this.doctorFrm.get('PhoneNumber');
  }
  get StartDate() {
    return this.doctorFrm.get('StartDate');
  }
  get EndDate() {
    return this.doctorFrm.get('EndDate');
  }
  get Status() {
    return this.doctorFrm.get('Status');
  }

  getDepartments(id) {
    this.hosptalService.getDepartment(id).subscribe((res) => {
      this.dptList = res.Departments;
    });
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.doctorService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
      },
      (err) => console.log(err)
    );
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    console.info('got webcam image', this.sysImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let doctorName = this.doctorFrm.value['DoctorName'];
    let licenceNumber = this.doctorFrm.value['LicenceNumber'];
    let npiNumber = this.doctorFrm.value['NpiNumber'];
    let doctorQualification = this.doctorFrm.value['DoctorQualification'];
    let doctorSpecialization = this.doctorFrm.value['DoctorSpecialization'];
    let doctorDOB = this.doctorFrm.value['DoctorDOB'];
    doctorDOB = moment(doctorDOB).format('MM/DD/YYYY');

    let email = this.doctorFrm.value['Email'];
    let phoneNumber =
      this.doctorFrm.value['CountryCode'] + this.doctorFrm.value['PhoneNumber'];

    let doctorAddress = this.doctorFrm.value['DoctorAddress'];
    let startDate = this.doctorFrm.value['StartDate'];
    let status = this.doctorFrm.value['Status'];
    let password = this.doctorFrm.value['Password'];

    let departmentId = this.doctorFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: this.hospitalName + email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerDoctor(userBody).subscribe((res) => {
      let body = {
        HospitalId: this.id,
        DoctorId: res.Id,
        DepartmentId: departmentId,
        DepartmentName: dptName,
        DoctorName: doctorName,
        NpiNumber: npiNumber,
        LicenceNumber: licenceNumber,
        DoctorQualification: doctorQualification,
        DoctorSpecialization: doctorSpecialization,
        DoctorDOB: doctorDOB,
        Email: email,
        PhoneNumber: phoneNumber,
        DoctorAddress: doctorAddress,
        StartDate: startDate,
        Status: status,
        Password: password,
        DoctorImage: this.sysImage ? this.sysImage : this.imageUrl,
      };

      this.doctorService.registerDoctor(body).subscribe((res) => {
        this.isSuccess = true;
        this.doctorFrm.reset();
        this.emailService
          .sendEmail({
            Email: body.Email,
            Subject: ' Login Credentials',
            Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binseraopd.com. </h3> `,
          })
          .subscribe();

        const smsBodyDetails =
          'Your Username ' +
          userBody.UserName +
          ' You may reset password now at https://binseraopd.com ';
        let smsBody = {
          PhoneNumber: phoneNumber,
          Body: smsBodyDetails,
        };
        this.emailService.sendSms(smsBody).subscribe();
        this.isSuccess = true;

        setTimeout(() => {}, 2000);
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
