import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/Models/department.model';
import { Hospital } from '../../Models/hospital.model';
import { HospitalService } from '../../services/hospital.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { EmailPayload } from 'src/app/Models/email.model';
import { TextMessage } from 'src/app/Models/textmessage.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { EmailService } from 'src/app/services/email.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user.service';
import { getFilteredDate } from 'src/app/shared/utility';
import { PatientVisit } from 'src/app/Models/patientvisit.model';

@Component({
  selector: 'app-hospital-dashboard',
  templateUrl: './hospital-dashboard.component.html',
  styleUrls: ['./hospital-dashboard.component.css'],
})
export class HospitalDashboardComponent implements OnInit {
  helper = new JwtHelperService();
  showItem = '';
  id = 0;
  myToken = window.localStorage.getItem('token');
  Revenue: any = {};
  hospitalList: Hospital[];
  Patients: any = {};
  PatientVisits: any = {};
  NewPatients: any[] = [];
  AlertsExecuted = false;
  HospitalStaff: any = {};
  NewVisits: any[] = [];
  SoftwareRentalFee: any = {};

  constructor(
    private hospitalService: HospitalService,
    private router: Router,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private emailServices: EmailService,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe((res) => {
    //   this.id = res['id'];
    // });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getHospitalRevene(this.id);
    this.getAllPatients(this.id);
    this.getAllPatientVisits(this.id);
    this.getHospitalStaff(this.id);
    if (!this.AlertsExecuted) {
      this.getAppointments();
    }
    const interval = 4 * 60 * 60 * 1000;
    setInterval(() => {
      this.getAppointments();
    }, interval);
  }

  upcomingAppointments = [];
  getAppointments() {
    this.appointmentService.getPatientBookings().subscribe({
      next: (res) => {
        this.upcomingAppointments = res.filter((appointment) => {
          const endDate = moment(appointment.VisitDate, 'MM/DD/YYYY').fromNow();
          moment().subtract(1, 'days').calendar(endDate);
          if (endDate && !appointment.IsNotified) {
            return appointment;
          }
        });
        this.sendAlerts();
      },
    });
  }

  sendAlerts() {
    this.upcomingAppointments.forEach((appointment) => {
      this.emailServices
        .sendEmail(this.setEmailPayload(appointment))
        .subscribe({
          next: (res) => {
            this.appointmentService
              .updatePatientBooking(appointment.PatientBookingId, {
                ...appointment,
                IsNotified: true,
              })
              .subscribe();
            this.emailServices
              .sendSms(this.setSmsPayload(appointment))
              .subscribe();
            this.AlertsExecuted = true;
          },
        });
    });
  }

  getHospitalStaff(id): void {
    const doctors = this.hospitalService.getDoctors(id);
    const nurses = this.hospitalService.getNurses(id);
    combineLatest([doctors, nurses]).subscribe((res) => {
      this.HospitalStaff.Doctors = {
        active: res[0].Doctors.filter(
          (doctor) => Boolean(doctor.Status) === Boolean('true')
        ).length,
        total: res[0].Doctors.length,
      };
      this.HospitalStaff.Nurses = {
        active: res[1].Nurses.filter((nurse) => nurse.Status === 'true').length,
        total: res[1].Nurses.length,
      };
    });
  }

  setEmailPayload(data): EmailPayload {
    return {
      Email: data.Email,
      Subject: `Appointment Reminder`,
      Template: `<h2 style="text-align:center">Appointment with ${data.DoctorName} ${data.DoctorSpecialization}</h2>
      <p>Hi , you have an upcoming appointment with ${data.DoctorName} ${data.DoctorSpecialization} on ${data.VisitDate} at ${data.VisitTime} </p>
      <a href="https://binseraopd.com">Cancel Appointment</a> <br/>
      <a href="https://binseraopd.com">Reschedule Appointment</a>
      `,
    };
  }

  setSmsPayload(data): TextMessage {
    const smsBody =
      'You have an upcoming appointment with ' +
      data.DoctorName.replace('.', ' ') +
      ' ' +
      data.DoctorSpecialization +
      ' on ' +
      data.VisitDate +
      ' at ' +
      data.VisitTime +
      ' ';
    return {
      PhoneNumber: data.PhoneNumber,
      Body: smsBody,
    };
  }

  getHospitalRevene(id) {
    this.hospitalService.GetInvoices(id).subscribe({
      next: (res: any) => {
        const unpaid = res.Invoices.map(
          (r) => r.Status === 'UnPaid' && r.Total
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const paid = res.Invoices.map(
          (r) => r.Status === 'Paid' && r.Total
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const pending = res.Invoices.map(
          (r) => r.Status === 'Pending' && r.Total
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const recievable = res.Invoices.map(
          (r) => r.Status === 'Balance' && r.BalanceAmount
        ).reduce((a, b) => Number(a) + Number(b), 0);

        const total = res.Invoices.map((r) => r.Total);
        this.Revenue['Total'] = total.reduce(
          (acc, curr) => Number(acc) + Number(curr),
          0
        );
        const fee = res.Invoices.map((r) => r.SoftwareFee);
        this.Revenue['SoftwareFee'] = fee.reduce(
          (acc, curr) => Number(acc) + Number(curr),
          0
        );
        // this.Revenue["Total"] = unpaid+paid+pending;
        this.Revenue['Unpaid'] = unpaid ? unpaid : 0;
        this.Revenue['Paid'] = paid ? paid : 0;
        this.Revenue['Pending'] = pending ? pending : 0;
        this.Revenue['Balance'] = recievable ? recievable : 0;
        const currentMonth = res.Invoices.filter((invoice) => {
          const startDate = getFilteredDate(new Date(new Date().setDate(1)));
          const endDate = getFilteredDate(new Date(new Date().setDate(30)));
          if (
            new Date(invoice.createdDate) <= new Date(endDate) ||
            new Date(invoice.createdDate) >= new Date(startDate)
          ) {
            return invoice;
          }
        });
        this.Revenue['MonthTotal'] = currentMonth
          .map((inv) => inv.Total)
          .reduce((acc, curr) => Number(acc) + Number(curr), 0);
        const Munpaid = currentMonth
          .map((r) => r.Status === 'UnPaid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const Mpaid = currentMonth
          .map((r) => r.Status === 'Paid' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);

        const Mpending = currentMonth
          .map((r) => r.Status === 'Pending' && r.Total)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const Mrecievable = currentMonth
          .map((r) => r.Status === 'Balance' && r.BalanceAmount)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const fees = currentMonth
          .map((r) => r.SoftwareFee)
          .reduce((a, b) => Number(a) + Number(b), 0);

        this.Revenue['MonthUnpaid'] = Munpaid ? Munpaid : 0;
        this.Revenue['MonthPaid'] = Mpaid ? Mpaid : 0;
        this.Revenue['MonthPending'] = Mpending ? Mpending : 0;
        this.Revenue['MonthReceivable'] = Mrecievable ? Mrecievable : 0;
        this.Revenue['SoftwareFee'] = fees ? fees : 0;
      },
    });
  }

  getAllPatients(id): void {
    this.hospitalService.getPatients(id).subscribe({
      next: (res) => {
        this.Patients['Active'] = res.Patients.filter(
          (patient) => patient.Status === 'Active'
        ).length;
        this.Patients['Discharged'] = res.Patients.filter(
          (patient) => patient.Status === 'Discharged'
        ).length;
        this.Patients['Total'] = res.Patients.filter(
          (patient) => patient
        ).length;
        this.NewPatients = res.Patients.filter((patient) =>
          this.isAddedInLast30Days(
            patient.CreatedDate,
            res.Patients.map((p) => p.CreatedDate)
          )
        );
        this.Patients['New'] = this.NewPatients.length;
        this.Patients['NewActive'] = this.NewPatients.filter(
          (patient) => patient.Status === 'Active'
        ).length;
        this.Patients['NewDischarged'] = this.NewPatients.filter(
          (patient) => patient.Status === 'Discharged'
        ).length;
      },
    });
  }

  isAddedInLast30Days(date, patients) {
    const now = new Date();
    const last30Days = now;
    last30Days.setDate(last30Days.getDate() - 30);

    const existsInLast30Days = patients.filter((patient) => {
      return (
        now.getTime() - new Date(patient).getTime() <= 30 * 24 * 60 * 60 * 1000
      );
    });

    return existsInLast30Days.includes(date);
  }
  getAllPatientVisits(id): void {
    this.hospitalService.GetPatientVisits(id).subscribe({
      next: (res) => {
        this.NewVisits = res.PatientVisits.filter((visit) =>
          this.visitsAddedInLast30Days(
            visit.createdDate,
            res.PatientVisits.map((p) => p.createdDate)
          )
        );
        const currentMonth = res.PatientVisits.filter((visit) => {
          const startDate = getFilteredDate(new Date(new Date().setDate(1)));
          const endDate = getFilteredDate(new Date(new Date().setDate(30)));
          if (
            new Date(visit.createdDate) <= new Date(endDate) ||
            new Date(visit.createdDate) >= new Date(startDate)
          ) {
            return visit;
          }
        });

        const fees = currentMonth
          .map((r) => r.SoftwareFee)
          .reduce((a, b) => Number(a) + Number(b), 0);
        this.PatientVisits['New'] = this.NewVisits.length;
        this.PatientVisits['SoftwareFee'] = fees ? fees : 0;

        // this.PatientVisits['NewActive'] = this.NewVisits.filter(
        //   (patient) => patient.SoftwareFee != null
        // ).length;
      },
    });
  }

  visitsAddedInLast30Days(date, patientVisits) {
    // const now = new Date();
    // const last30Days = now;
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 30);

    const existsInLast30Days = patientVisits.filter((patient) => {
      return (
        today.getTime() - new Date(patient).getTime() <=
        30 * 24 * 60 * 60 * 1000
      );
    });

    return existsInLast30Days.includes(date);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
