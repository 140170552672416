<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>




    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
    </ng-container>
    <ng-container matColumnDef="Subject">
      <th mat-header-cell *matHeaderCellDef> Subject </th>
      <td mat-cell *matCellDef="let row"> {{row.Subject}} </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th class="message" mat-header-cell *matHeaderCellDef> Descriptive Reason for order </th>
      <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
    </ng-container>
    <ng-container matColumnDef="OrderedBy">
      <th mat-header-cell *matHeaderCellDef> Ordered By </th>
      <td mat-cell *matCellDef="let row"> {{row.OrderedBy}} </td>
    </ng-container>


    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> Print </th>
      <td mat-cell *matCellDef="let row">
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg"> Print
        </button>

        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Print Order</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="card" #LabOrder>
                <div class="card-body">
                  <div class="modal-body " id="printable">
                    <app-view-hospital></app-view-hospital>

                    <div class="areaStyle mb-1">
                      <P>Patient ID: {{patient?.PatientId}} </P>

                      <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                      <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                      <P>Patient Address: {{patient?.PatientAddress}} </P>
                      <hr>
                    </div>
                    <P>Date: {{row.Date | date:'short'}}</P>
                    <P>Subject: {{row.Subject}}</P>
                    <P>OrderedBy: {{row.OrderedBy}}</P>
                    <P>Descriptive Reason for order: {{row.Description}}</P>
                  </div>
                </div>
              </div>
              <div>
                <form action="">
                  <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                    <mat-label>Email to Lab</mat-label>
                    <input matInput type="text" [formControl]="email" placeholder="Enter email">
                  </mat-form-field>
                  <button mat-raised-button color="primary" class="mb-1" (click)="sendtoLab()">Send</button>
                  <div class="send-fax-prescription d-flex align-items-baseline">
                    <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                      <input matInput type="text" [formControl]="fax" placeholder="Enter Fax Number">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="mb-1" (click)="faxPresciption()">Send</button>
                  </div>
                  <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Fax has been sent Successfully.
                    <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div *ngIf="errorMessage" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    {{errorMessage}}
                    <button type="button" class="close " data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
              </div>
            </div>
          </div>
        </div>

      </td>
    </ng-container>
    <!-- modal  -->



    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button (click)="delete(row.LabOrderId)" mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>