import { Component } from '@angular/core';

@Component({
  selector: 'app-view-dicom',
  templateUrl: './view-dicom.component.html',
  styleUrls: ['./view-dicom.component.css']
})
export class ViewDicomComponent {

}
